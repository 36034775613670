import styled from "styled-components";
import { Field, Form } from "formik";

export const HomeContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const LoginBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 50%;
`;

export const LoginFormikForm = styled(Form)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 24px;
  border: 2px solid #ff8701;
  border-radius: 8px;
  min-width: 260px;
  width: 100%;
`;

export const LoginButton = styled.button`
  border: 1px solid gray;
  border-radius: 8px;
  padding: 8px;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  :hover {
    box-shadow: 2px 2px 2px gray;
    font-weight: bold;
  }
`;

export const AlternateBox = styled.div`
  display: flex;
  flex-direction: column;

  img {
    width: 100%;
  }
`;
