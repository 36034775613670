import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../../assets/images/close.png";
import { InputGroup } from "../../../../shared/InputGroup/InputGroup";
import { BlockData } from "../../../../shared/Constants/blocks";
import { SelectGroup } from "../../../../shared/SelectGroup/SelectGroup";

import { getAllSuppliers } from "../../../../reduxLogic/reducers/suppliers/getAllSupplierSlice";
import {
  getSingleSupplier,
  resetSingleSupplierData,
} from "../../../../reduxLogic/reducers/suppliers/getSingleSupplierSlice";
import {
  editSingleSupplier,
  resetErrorinEditSupplier,
  resetMessageinEditSupplier,
} from "../../../../reduxLogic/reducers/suppliers/editSingleSupplierSlice";
import {
  renderPreviewForCurrent,
  renderPreviewForPrevious,
} from "../../../../shared/RenderPreview/RenderPreview";
import Swal from "sweetalert2";

const initialValues = {
  supplierId: "",
  supplierName: "",
  supplierPrimaryMobile: "",
  supplierSecondaryMobile: "",
  supplierEmail: "",
  contactPerson: "",
  supplierAddress: {
    supplierAddressLine: "",
    supplierDistrict: "",
    supplierCity: "",
    supplierState: "",
    supplierPincode: "",
  },
  notes: "",
  GSTIN: "",
  documents: [],
  isActive: true,
  currentToken: "",
};

const suppliersSchema = Yup.object().shape({
  supplierName: Yup.string().required("Supplier Name is required"),
  supplierPrimaryMobile: Yup.string()
    .required("Farmer Mobile is required")
    .matches(/^(91\+)?\d{10}$/, "Primary Mobile number is not valid"),
  supplierSecondaryMobile: Yup.string()
    .nullable()
    .matches(/^(91\+)?\d{10}$/, "Secondary Mobile number is not valid"),

  supplierEmail: Yup.string()
    .email("Invalid email")
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Email is in incorrect format"
    )
    .required("Supplier Email is Required"),
  contactPerson: Yup.string().required("Contact Person is required"),
  supplierAddress: Yup.object().shape({
    supplierAddressLine: Yup.string().required("Address line is required"),
    supplierDistrict: Yup.string().required("District is required"),
    supplierCity: Yup.string().required("City is required"),
    supplierState: Yup.string().required("State is required"),
    supplierPincode: Yup.string()
      .required("Pincode is required")
      .matches(/^[0-9]{6}$/, "Invalid pincode"),
  }),
  GSTIN: Yup.string()
    .nullable()
    .matches(
      /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
      "GSTIN is not Valid"
    ),
});

const EditSupplierForm = (props) => {
  const { suppliersData } = useSelector((state) => state.allSuppliers);
  //console.log("this is allSuppliers from  SupplierForm ==> ", suppliersData);

  // const singleSuppliersData = useSelector(
  //   (state) => state?.getSingleSupplier?.suppliersData
  // );

  const { error, message } = useSelector((state) => state.editSingleSupplier);

  const {
    openEditModal,
    setOpenEditModal,
    idToEdit,
    setIdToEdit,
    singleRow,
    setSingleRow,
  } = props;

  const [singleSuppliersData, setSingleSuppliersData] = useState(
    singleRow || {}
  );

  const [customNotes, setCustomNotes] = useState(singleSuppliersData?.notes);
  const [allDocuments, setAllDocuments] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [supplierDocuments,setSupplierDocuments] = useState(singleSuppliersData?.documents || []);

  // console.log(
  //   "this is singleSuppliersData to edit the supplier => ",
  //   singleSuppliersData
  // );

  const formikRef = useRef(null);
  const handleDocumentsChange = (e, index) => {
    const { files } = e.target;

    setAllDocuments((prevDocuments) => [...prevDocuments, ...files]);
  };

  useEffect(() => {
    if (singleSuppliersData) {
      setCustomNotes(singleSuppliersData.notes);
      setSelectedState(singleSuppliersData?.supplierAddress?.supplierState);
      setSelectedDistrict(
        singleSuppliersData?.supplierAddress?.supplierDistrict
      );
      setSelectedCity(singleSuppliersData?.supplierAddress?.supplierCity);
    }

    //console.log("this is custom notes in useEffect ==> ", customNotes);
  }, [singleSuppliersData]);

  const dispatch = useDispatch();

  const handleGetSingleSupplier = async () => {
    dispatch(getSingleSupplier(idToEdit));
  };

  useEffect(() => {
    handleGetSingleSupplier();
  }, [idToEdit]);

  useEffect(() => {
    // Extract unique states from the JSON data
    const initialUniqueStates = [
      ...new Set(BlockData.map((item) => item.state)),
    ];
    setStates(initialUniqueStates);
  }, []);

  useEffect(() => {
    // Extract unique states from the JSON data
    const initialUniqueDistricts = [
      ...new Set(
        BlockData.filter(
          (item) =>
            item.state === singleSuppliersData?.supplierAddress?.supplierState
        ).map((item) => item.district)
      ),
    ];
    setDistricts(initialUniqueDistricts);
  }, [singleSuppliersData]);

  useEffect(() => {
    // Extract unique states from the JSON data
    const initialUniqueCities = [
      ...new Set(
        BlockData.filter(
          (item) =>
            item.district ===
            singleSuppliersData?.supplierAddress?.supplierDistrict
        ).map((item) => item.city)
      ),
    ];
    //console.log("initialUniqueCities => ", initialUniqueCities);
    setCities(initialUniqueCities);
  }, [singleSuppliersData]);

  const handleStateChange = (tempSelectedState) => {
    setSelectedState(tempSelectedState);
    // Extract unique districts based on the selected state
    const uniqueDistricts = [
      ...new Set(
        BlockData.filter((item) => item.state === tempSelectedState).map(
          (item) => item.district
        )
      ),
    ];
    setDistricts(uniqueDistricts);
  };

  const handleDistrictChange = (tempSelectedDistrict) => {
    setSelectedDistrict(tempSelectedDistrict);
    // Extract unique Cities based on the selected district
    const uniqueCities = [
      ...new Set(
        BlockData.filter((item) => item.district === tempSelectedDistrict).map(
          (item) => item.city
        )
      ),
    ];
    setCities(uniqueCities);
  };

  const handleCityChange = (tempSelectedCity) => {
    setSelectedCity(tempSelectedCity);
  };

  const handleClose = () => {
    dispatch(resetSingleSupplierData());
    setOpenEditModal(false);
  };
  const handleNotesChange = (value) => {
    setCustomNotes(value);
  };

  useEffect(() => {
    formikRef.current.setValues(singleSuppliersData);
  }, [singleSuppliersData]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };

  const handleClearSelection = (e) => {
    e.preventDefault();
    const fileInput = document.querySelector('input[name="documents"]');
    if (fileInput) {
      fileInput.value = null;
    }
    setAllDocuments([]);
  };

  const handleSubmit = (values) => {
    console.log("these are supplier values in handleSubmit => ", values);
    setIsEditing(true);

    let formData = new FormData();
    const token = localStorage.getItem("humblebeeAuthToken");

    formData.append("supplierId", singleSuppliersData.supplierId);

    let tempSupplierName = values.supplierName
      ? values.supplierName
      : singleSuppliersData.supplierName;

    formData.append("supplierName", tempSupplierName);

    let tempSupplierPrimaryMobile = values.supplierPrimaryMobile
      ? values.supplierPrimaryMobile
      : singleSuppliersData.supplierPrimaryMobile;
    formData.append("supplierPrimaryMobile", tempSupplierPrimaryMobile);

    console.log(
      "values.supplierPrimaryMobile  =>  ",
      values.supplierPrimaryMobile
    );
    console.log(
      "this is tempSupplierPrimaryMobile => ",
      tempSupplierPrimaryMobile
    );

    let tempSupplierSecondaryMobile = values.supplierSecondaryMobile
      ? values.supplierSecondaryMobile
      : singleSuppliersData.supplierSecondaryMobile || "";

    formData.append("supplierSecondaryMobile", tempSupplierSecondaryMobile);

    let tempSupplierCity = values?.supplierAddress?.supplierCity
      ? values?.supplierAddress?.supplierCity
      : singleSuppliersData.supplierAddress.supplierCity;

    formData.append("supplierAddress.supplierCity", tempSupplierCity);

    let tempSupplierState = values?.supplierAddress?.supplierState
      ? values?.supplierAddress?.supplierState
      : singleSuppliersData.supplierAddress.supplierState;
    formData.append("supplierAddress.supplierState", tempSupplierState);

    let tempSupplierDistrict = values?.supplierAddress?.supplierDistrict
      ? values?.supplierAddress?.supplierDistrict
      : singleSuppliersData.supplierAddress.supplierDistrict;
    formData.append("supplierAddress.supplierDistrict", tempSupplierDistrict);

    let tempSupplierPincode = values?.supplierAddress?.supplierPincode
      ? values?.supplierAddress?.supplierPincode
      : singleSuppliersData.supplierAddress.supplierPincode;
    formData.append("supplierAddress.supplierPincode", tempSupplierPincode);

    let tempSupplierAddressLine = values?.supplierAddress?.supplierAddressLine
      ? values?.supplierAddress?.supplierAddressLine
      : singleSuppliersData.supplierAddress.supplierAddressLine;
    formData.append(
      "supplierAddress.supplierAddressLine",
      tempSupplierAddressLine
    );

    let tempSupplierEmail = values.supplierEmail
      ? values.supplierEmail
      : singleSuppliersData.supplierEmail;
    formData.append("supplierEmail", tempSupplierEmail);

    let tempContactPerson = values.contactPerson
      ? values.contactPerson
      : singleSuppliersData.contactPerson;
    formData.append("contactPerson", tempContactPerson);

    let tempGSTIN = values.GSTIN ? values.GSTIN : singleSuppliersData.GSTIN;
    formData.append("GSTIN", tempGSTIN);

    let tempCustomNotes = customNotes ? customNotes : singleSuppliersData.notes;
    formData.append("notes", tempCustomNotes);

    let tempAllDocuments =
      allDocuments?.length > 0 ? [...allDocuments,...supplierDocuments] : supplierDocuments || singleSuppliersData.documents;

    // console.log("tempAllDocuments ==> ", tempAllDocuments);
    // Add each file in the documents array to formData
    for (let i = 0; i < tempAllDocuments.length; i++) {
      formData.append(`documents[${i}]`, tempAllDocuments[i]);
      // console.log("alldocuments[i] => ", alldocuments[i]);
      // console.log("typeOf ", typeof alldocuments[i]);
    }

    let tempIsActive = values.isActive;

    formData.append("isActive", tempIsActive);

    formData.append("currentToken", token);
    let editId = singleSuppliersData?._id;
    //console.log("this is editId => ", editId);
    // console.log("this is just above final dispatch formData", formData);

    dispatch(editSingleSupplier({ editId, formData }))
      .then(() => dispatch(resetSingleSupplierData()))
      .then(() => dispatch(getAllSuppliers()))
      .then(() => setOpenEditModal(false))
      .then(() => setIsEditing(false))
      .catch((err) => toast.error(`${err}`));

    // End of handleSubmit
  };

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinEditSupplier());
      dispatch(resetMessageinEditSupplier());
    }

    if (!error && message) {
      toast.success("Supplier was updated succesfully");
      dispatch(resetMessageinEditSupplier());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        {singleSuppliersData ? (
          <Formik
            initialValues={
              singleSuppliersData ? singleSuppliersData : initialValues
            }
            onSubmit={handleSubmit}
            validationSchema={suppliersSchema}
            validateOnMount
            innerRef={formikRef}
          >
            {(formik) => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit Supplier Form</h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt="Close" />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                {/* Main Bode of Form Starts */}
                <Styles.MainSupplierFormDiv>
                  <InputGroup
                    PlaceholderText="Enter Supplier Name"
                    LabelText="Supplier Name"
                    star="*"
                    name="supplierName"
                    type="text"
                    defaultValue={singleSuppliersData?.supplierName}
                    onChange={formik.handleChange}
                  />
                  <Styles.RowFlex>
                    <InputGroup
                      PlaceholderText="Enter Primary Mobile Number"
                      LabelText="Primary Mobile Number"
                      name="supplierPrimaryMobile"
                      type="number"
                      star="*"
                      defaultValue={singleSuppliersData?.supplierPrimaryMobile}
                      onChange={formik.handleChange}
                      onKeyDown={handleKeyDown}
                    />

                    <InputGroup
                      PlaceholderText="Enter Secondary Mobile Number"
                      LabelText="Secondary Mobile Number"
                      name="supplierSecondaryMobile"
                      type="number"
                      defaultValue={
                        singleSuppliersData?.supplierSecondaryMobile
                      }
                      onChange={formik.handleChange}
                    />
                  </Styles.RowFlex>

                  <InputGroup
                    PlaceholderText="Enter Email"
                    LabelText="Email"
                    name="supplierEmail"
                    type="email"
                    star="*"
                    defaultValue={singleSuppliersData?.supplierEmail}
                    onChange={formik.handleChange}
                  />

                  <InputGroup
                    PlaceholderText="Enter Contact Person"
                    LabelText="Contact Person"
                    name="contactPerson"
                    type="text"
                    star="*"
                    defaultValue={singleSuppliersData?.contactPerson}
                    onChange={formik.handleChange}
                  />

                  <InputGroup
                    PlaceholderText="Enter Address"
                    LabelText="Address"
                    name="supplierAddress.supplierAddressLine"
                    type="text"
                    star="*"
                    defaultValue={
                      singleSuppliersData?.supplierAddress?.supplierAddressLine
                    }
                    onChange={formik.handleChange}
                  />

                  <Styles.RowFlex>
                    <SelectGroup
                      PlaceholderText="Select State"
                      LabelText="State"
                      name="supplierAddress.supplierState"
                      onChange={(e) => {
                        handleStateChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={selectedState}
                      defaultValue={selectedState} // Add this line
                      options={states}
                      star="*"
                    />

                    <SelectGroup
                      PlaceholderText="Select District"
                      LabelText="District"
                      name="supplierAddress.supplierDistrict"
                      onChange={(e) => {
                        handleDistrictChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={selectedDistrict}
                      defaultValue={selectedDistrict} // Add this line
                      options={districts}
                      star="*"
                    />
                  </Styles.RowFlex>
                  <Styles.RowFlex>
                    <SelectGroup
                      PlaceholderText="Select City"
                      LabelText="City"
                      name="supplierAddress.supplierCity"
                      options={cities}
                      onChange={(e) => {
                        handleCityChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={selectedCity} // Add this line
                      defaultValue={selectedCity}
                      star="*"
                    />
                    <InputGroup
                      PlaceholderText="Enter Pincode"
                      LabelText="Pincode"
                      name="supplierAddress.supplierPincode"
                      type="number"
                      star="*"
                      defaultValue={
                        singleSuppliersData?.supplierAddress?.supplierPincode
                      }
                      onChange={formik.handleChange}
                    />
                  </Styles.RowFlex>

                  <Styles.MarginTopBottomBox>
                    <Styles.Labels>Notes</Styles.Labels>
                    <ReactQuill
                      style={{ marginTop: "10px" }}
                      onChange={(value) => handleNotesChange(value)}
                      value={customNotes}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link", "image"],
                          ["clean"],
                        ],
                      }}
                    />
                  </Styles.MarginTopBottomBox>

                  <InputGroup
                    PlaceholderText="Enter GSTIN"
                    LabelText="GSTIN"
                    name="GSTIN"
                    type="text"
                    defaultValue={singleSuppliersData?.GSTIN}
                    onChange={formik.handleChange}
                  />

                  <div style={{ marginTop: "10px" }}>
                    <Styles.Labels id="isSupplierActiveGroup">
                      Is Supplier Active <span style={{ color: "red" }}>*</span>
                    </Styles.Labels>
                    <Styles.RadioButtonGroup
                      role="isActive"
                      aria-labelledby="isSupplierActiveGroup"
                    >
                      <label>
                        <Field
                          type="radio"
                          name="isActive"
                          value={true}
                          //defaultValue={singleSuppliersData.isActive}
                          checked={formik?.values?.isActive == true}
                          onChange={() =>
                            formik.setFieldValue("isActive", true)
                          }
                        />
                        <span style={{ marginLeft: "8px" }}>Yes</span>
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="isActive"
                          value={false}
                          //defaultValue={singleSuppliersData.isActive}
                          checked={formik?.values?.isActive == false}
                          onChange={() =>
                            formik.setFieldValue("isActive", false)
                          }
                        />
                        <span style={{ marginLeft: "8px" }}>No</span>
                      </label>
                    </Styles.RadioButtonGroup>
                  </div>

                  <Styles.ColumnFlex>
                    <Styles.Labels>Documents</Styles.Labels>
                    <Styles.FileInputClearButtonDiv>
                      <button onClick={(e) => handleClearSelection(e)}>
                        <img src={close} alt="clear" />
                      </button>
                      <Styles.FileInput
                        type="file"
                        multiple
                        onChange={(e, index) => handleDocumentsChange(e, index)}
                        name="documents"
                        accept=".pdf, image/*"
                      />
                    </Styles.FileInputClearButtonDiv>

                    <Styles.IMGContainer>
                      <Styles.IMGTextBox>
                        <p>Previous</p>
                        {/* <Styles.IMGBox>
                          {formik?.values?.documents?.map((item, index) => (
                            <div key={index} className="image" 
                            style={{ height:"30px",width:"100px",display:"flex" }}>
                              <div className="caption" style={{background:"lightgrey"}} >
                                 {image.endsWith(".pdf") ?  <a className="anchor-tag" href={image}  target="_blank"
                                        rel="noopener noreferrer" >{`PDF Link ${index}`}</a> : 
                                  <a className="anchor-tag" href={image} target="_blank" rel="noopener noreferrer" style={{marginLeft:3}}>Photo</a> }
                              <button className="delete-button" style={{marginTop:4,marginRight:2}} 
                              type="button" onClick={() => {  remove(index)
                                setSupplierDocuments(()=> supplierDocuments.filter((_, i) => i !== index))
                                }}>
                                <img src={close} alt="clear"  style={{height:'10px'}}/></button>
                                </div>
                            </div>

                          ))}
                        </Styles.IMGBox> */}

            <FieldArray name="documents">
                  {({ push, remove }) => (
                <div style={{display:"flex"}}>
                  {formik?.values?.documents?.map((image, index) => (
                    <div key={index} className="image" 
                    style={{ height:"30px",width:"100px",display:"flex" }}>
                      <div className="caption" style={{background:"lightgrey"}} >
                         {image.endsWith(".pdf") ?  <a className="anchor-tag" href={image}  target="_blank"
                                rel="noopener noreferrer" >{`PDF Link ${index}`}</a> : 
                          <a className="anchor-tag" href={image} target="_blank" rel="noopener noreferrer" style={{marginLeft:3}}>Photo</a> }
                      <button className="delete-button" style={{marginTop:4,marginRight:2}} 
                      type="button" onClick={() => {  
                        

                        Swal.fire({
                          title: 'Delete Confirmation',
                          text: 'Are you sure you want to delete this item?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: 'rgb(255, 135, 1)',
                          cancelButtonColor: 'lightgrey',
                          confirmButtonText: 'Delete',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            remove(index)
                            setSupplierDocuments(()=> supplierDocuments.filter((_, i) => i !== index)) 
                            Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                          }
                        });
                        }}>
                        <img src={close} alt="clear"  style={{height:'10px'}}/></button>
                        </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
                      </Styles.IMGTextBox>

                      <Styles.IMGTextBox>
                        <p>Current</p>
                        <Styles.IMGBox>
                          {allDocuments?.length > 0
                            ? allDocuments?.map((item, index) => (
                              <div key={index} className="image" style={{ height:"100px",width:"120px",display:"flex" }}>
                              <div>
                                {renderPreviewForCurrent(item, index)}
                              </div>
                              <button className="delete-button" type="button" onClick={() => {
                                  // let filterImage = allDocuments.filter((item,i) => i !== index );
                                  // setAllDocuments(filterImage) 
                                  Swal.fire({
                                    title: 'Delete Confirmation',
                                    text: 'Are you sure you want to delete this item?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonColor: 'rgb(255, 135, 1)',
                                    cancelButtonColor: 'lightgrey',
                                    confirmButtonText: 'Delete',
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      let filterImage = allDocuments.filter((item,i) => i !== index );
                                      setAllDocuments(filterImage) 
                                      // let filterImage = allMedia.filter((item,i) => i !== index );
                                      //                               setAllMedia(filterImage) 
                                      Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                                    }
                                  });
                                  
                                  }}>
                                  <img src={close} alt="clear"  style={{height:'10px'}}/>
                               </button>
                              </div>
                              ))
                            : null}
                        </Styles.IMGBox>
                      </Styles.IMGTextBox>
                    </Styles.IMGContainer>
                  </Styles.ColumnFlex>
                </Styles.MainSupplierFormDiv>

                {/* Main Body of Form stops */}

                <Styles.CreateSupplierButton type="submit">
                  {!isEditing ? "Edit" : "...editing"}
                </Styles.CreateSupplierButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <h4>There was error fetching data for Supplier with ID {idToEdit}</h4>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default EditSupplierForm;
