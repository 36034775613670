/** @format */

import React, { useEffect, useMemo, useState } from "react";
import * as Styles from "./style";
import { Link,useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { changeDateTimeFormat } from "../../shared/Helper";
import { usePagination, useTable, useSortBy } from "react-table";
import editIcon from "../../assets/images/edit.png";
import { useDispatch, useSelector } from "react-redux";
import deleteIcon from "../../assets/images/delete.png";
import { getAllBeeMitras } from "../../reduxLogic/reducers/beeMitra/getAllBeeMitrasSlice";
import BeeMitrasForm from "./BeeMitrasForm/BeeMitrasForm";
import EditBeeMitraForm from "./EditBeeMitraForm/EditBeeMitraForm";
import DeleteModal from "./DeleteModal/DeleteModal";
import EditStatusModal from "./EditStatusModal/EditStatusModal";


const BeeMitras = () => {
  const {projectId} =useParams();
console.log('✌️projectId --->', projectId);
  const Beemitra_TABLE_COLUMNS = [
    { Header: "S.No", accessor: "sNo" },
    { Header: "Bee Mitra", accessor: "name" },
    { Header: "Mobile", accessor: "mobile" },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <span>
          {row.original.status}
          <button onClick={() => handleOpenEditStatusModal(row.original._id, row.original)}>
            Edit
          </button>
        </span>
      ),
    },
    {Header: "Edit"},

  ];
  const [openModal, setOpenModal] = useState(false);
  const [singleRow, setSingleRow] = useState({});
  const [idToEdit, setIdToEdit] = useState("");
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openEditStatusModal, setOpenEditStatusModal] = useState(false);
  const currentToken = localStorage.getItem("humblebeeAuthToken");
  const decoded = jwt_decode(currentToken);
  const role = decoded.role;

  const handleModalToggle = () => {
    setOpenModal((prev) => !prev);
  };
  const handleOpenEditModal = (id, singleRow) => {
    //console.log("this is id in handleOpenEdit => ", id);
    //dispatch(getSingleSupplier(id));
    setSingleRow(singleRow);
    setIdToEdit(id);
    setOpenEditModal(true);
  };
  const handleOpenEditStatusModal = (id, singleRow) => {

    setSingleRow(singleRow);
    setIdToEdit(id);
    setOpenEditStatusModal(true);
  };
  const { beeMitrasData } = useSelector((state) => state.allBeeMitras);

  const dispatch = useDispatch();

  const fetchAllBeeMitraData = (id) => {
  
   
    dispatch(getAllBeeMitras(id));
  };

  const columns = useMemo(() => {
    // Add a new column for S.No
    const newColumns = [...Beemitra_TABLE_COLUMNS];
    newColumns[0] = {
      Header: "S.No",
      accessor: (_, index) => `BM${index + 1}`, // Dynamic serial numbers 
    };
    return newColumns;
  }, []);
  const data = useMemo(() => beeMitrasData, [beeMitrasData]);

  useEffect(() => {
   
    fetchAllBeeMitraData(projectId);
  }, [dispatch,projectId]);
  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },

    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance;
  const handleOpenDeleteModal = (id) => {
    setDeleteModal(true);
    setIdToDelete(id);
  };
  return (
    <Styles.SupplierBox>
      <Styles.SupplierTopSection>
        <Styles.ModalButton onClick={handleModalToggle}>
          Add Bee Mitra
        </Styles.ModalButton>
        {openModal ? (
          <BeeMitrasForm openModal={openModal} setOpenModal={setOpenModal} />
        ) : null}
      </Styles.SupplierTopSection>

      <Styles.TableContainer>
        {beeMitrasData && beeMitrasData ? (
          <Styles.Table {...getTableProps()}>
            <Styles.THead>
              {headerGroups.map((headerGroup) => (
                <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((headerColumn) => (
                    <Styles.TH
                      {...headerColumn.getHeaderProps(
                        headerColumn.getSortByToggleProps()
                      )}
                    >
                      {headerColumn.render("Header")}
                      <span>
                        {headerColumn.isSorted
                          ? headerColumn.isSortedDesc
                            ? " 🔼"
                            : " 🔽"
                          : ""}
                      </span>
                    </Styles.TH>
                  ))}
                </Styles.THeadRow>
              ))}
            </Styles.THead>

            <Styles.TBody {...getTableBodyProps()}>
              {page.map((singleRow, index) => {
                prepareRow(singleRow);

                return (
                  <Styles.TBodyRow {...singleRow.getRowProps()}>
                    {singleRow.cells.map((singleCell, index) => {
                      // console.log("singleCell qrCode ", singleCell);

                      return (
                        <Styles.TD key={index}>
                          {singleCell.column.Header === "Beekeepers" ? (
                          <Link to={`/beekeepers`}>
                          {singleCell.render("Cell")}
                        </Link>
                          ) : (
                            <span>
                              {singleCell.column.id == "addedOn" ||
                              singleCell.column.id == "updatedOn" ? (
                                <span>
                                  {changeDateTimeFormat(singleCell.value)}
                                </span>
                              ) : (
                                <span>
                                  {singleCell.column.Header === "Edit" ? (
                                    <Styles.ActionSpan>
                                      <button
                                        onClick={() =>
                                          handleOpenEditModal(
                                            singleCell?.row?.original?._id,
                                            singleRow?.original
                                          )
                                        }
                                      >
                                        <img src={editIcon} alt='Edit' />{" "}
                                      </button>

                                      {role == "Admin" ? (
                                        <button
                                          onClick={() =>
                                            handleOpenDeleteModal(
                                              singleCell?.row?.original?._id
                                              
                                            )
                                          }
                                        >
                                          <img src={deleteIcon} alt='Delete' />
                                        </button>
                                      ) : null}
                                    </Styles.ActionSpan>
                                  ) : (
                                    <span>{singleCell.render("Cell")}</span>
                                  )}
                                </span>
                              )}
                            </span>
                          )}
                        </Styles.TD>
                      );
                    })}
                  </Styles.TBodyRow>
                );
              })}
            </Styles.TBody>
          </Styles.Table>
        ) : (
          <h3>There was some error displaying table</h3>
        )}
      </Styles.TableContainer>
      {openEditModal ? (
        <EditBeeMitraForm
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          idToEdit={idToEdit}
          setIdToEdit={setIdToEdit}
          singleRow={singleRow}
          setSingleRow={setSingleRow}
        />
      ) : null}
         {openEditStatusModal ? (
        <EditStatusModal
          openEditStatusModal={openEditStatusModal}
          setOpenEditStatusModal={setOpenEditStatusModal}
          idToEdit={idToEdit}
          setIdToEdit={setIdToEdit}
          singleRow={singleRow}
          setSingleRow={setSingleRow}
        />
      ) : null}
           {openDeleteModal ? (
        <DeleteModal idToDelete={idToDelete} setDeleteModal={setDeleteModal} />
      ) : null}
    </Styles.SupplierBox>
  );
};

export default BeeMitras;
