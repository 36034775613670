import React from "react";

const NoRouteFound = () => {
  return (
    <div>
      <h1>No Route Found</h1>
    </div>
  );
};

export default NoRouteFound;
