import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import close from "../../../../assets/images/close.png";
import { InputGroup } from "../../../../shared/InputGroup/InputGroup";
import { TextareaGroup } from "../../../../shared/TextareaGroup/TextareaGroup";
import {
  makeFarmer,
  resetErrorinMakeFarmer,
  resetMessageinMakeFarmer,
} from "../../../../reduxLogic/reducers/farmers/makeFarmerSlice";
import { getAllFarmers } from "../../../../reduxLogic/reducers/farmers/getAllFarmersSlice";
import { MultiSelect } from "react-multi-select-component";
import { BlockData } from "../../../../shared/Constants/blocks";
import { SelectGroup } from "../../../../shared/SelectGroup/SelectGroup";

import {
  GoogleMap,
  useJsApiLoader,
  LoadScript,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";

import {
  DUMMY_HONEY_TYPES,
  DUMMY_HONEY_TYPES_WITH_LABELS,
  statesList,
} from "../../../../shared/Constants/Constants";

import "./style.css";
import { FieldErrorMessage } from "../../../../shared/ErrorMessage/FieldErrorMessage";
import { getAllHoney } from "../../../../reduxLogic/reducers/honeys/getAllHoneySlice";
import { renderPreviewForCurrent } from "../../../../shared/RenderPreview/RenderPreview";
import Swal from "sweetalert2";

const initialValues = {
  farmerName: "",
  location: {
    latitude: "",
    longitude: "",
  },
  farmerMobile: "",
  addressLine: "",
  district: "",
  city: "",
  state: "",
  pincode: "",
  produce: [],
  bio: "",
  farmerQuote: "",
  farmerAvatar: "",
  media: [],
  isActive: true,
  currentToken: "",
};

const farmerSchema = Yup.object().shape({
  farmerName: Yup.string().required("Farmer Name is required"),
  //produce: Yup.array().min(1, "At least one produce is required"),
  farmerMobile: Yup.string()
    .required("Farmer Mobile is required")
    .matches(/^[+]?[0-9]{10,15}$/, "Mobile number is not valid"),
  addressLine: Yup.string().required("Address is required"),
  city: Yup.string().required("City is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]{6}$/, "Invalid pincode"),
});

const FarmerForm = (props) => {
  const { openModal, setOpenModal } = props;
  const [location, setLocation] = useState({ lat: 20.59, lng: 78.96 });
  const [allMedia, setAllMedia] = useState([]);
  const [selectedFarmerAvatar, setSelectedFarmerAvatar] = useState("");
  const [customBioValue, setCustomBioValue] = useState("");
  const [selected, setSelected] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const { message, error } = useSelector((state) => state.makeFarmer);

  const handleMediaChange = (e, index) => {
    const { files } = e.target;
    setAllMedia((prevMedia) => [...prevMedia, ...files]);
  };

  const handleFarmerAvatarChange = (e, index) => {
    const { files } = e.target;
    console.log("files for farmerAvatar => ", files[0]);
    setSelectedFarmerAvatar(files[0]);
  };

  useEffect(() => {
    console.log("selectedFarmerAvatar => ", selectedFarmerAvatar);
  }, [selectedFarmerAvatar]);

  const handleBioChange = (value) => {
    // const bioValue = e.target.value;
    // console.log("this is bioValue from handleBioChange => ", value);
    setCustomBioValue(value);
  };
  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenModal((prev) => !prev);
  };

  useEffect(() => {
    dispatch(getAllHoney());
  }, []);

  const { honeyData } = useSelector((state) => state.allHoneys);

  // const newHoneyArray = honeyData?.map(
  //   (item) => `${item.honeyId}-${item.floralSource}`
  // );

  // const honeyNamesWithLabels = newHoneyArray?.map((produce) => ({
  //   label: produce,
  //   value: produce,
  // }));

  const honeyNamesWithLabels = honeyData?.map((honeyObj) => ({
    label: `${honeyObj.honeyId}-${honeyObj.floralSource}`,
    value: honeyObj._id,
  }));

  useEffect(() => {
    // Extract unique states from the JSON data
    const uniqueStates = [...new Set(BlockData.map((item) => item.state))];
    setStates(uniqueStates);
  }, []);

  const handleStateChange = (tempSelectedState) => {
    setSelectedState(tempSelectedState);
    // Extract unique districts based on the selected state
    const uniqueDistricts = [
      ...new Set(
        BlockData.filter((item) => item.state === tempSelectedState).map(
          (item) => item.district
        )
      ),
    ];
    setDistricts(uniqueDistricts);
  };

  const handleDistrictChange = (tempSelectedDistrict) => {
    setSelectedDistrict(tempSelectedDistrict);
    // Extract unique Cities based on the selected district
    const uniqueCities = [
      ...new Set(
        BlockData.filter((item) => item.district === tempSelectedDistrict).map(
          (item) => item.city
        )
      ),
    ];
    setCities(uniqueCities);
  };

  const handleCityChange = (tempSelectedCity) => {
    setSelectedCity(tempSelectedCity);
  };

  const handleClearSelection = (e) => {
    e.preventDefault();
    const fileInput = document.querySelector('input[name="media"]');
    if (fileInput) {
      fileInput.value = null;
    }
    setAllMedia([]);
  };
  const handleClearFarmerAvatarSelection = (e) => {
    e.preventDefault();
    const fileAvatarInput = document.querySelector(
      `input[name="farmerAvatar"]`
    );
    if (fileAvatarInput) {
      fileAvatarInput.value = null;
    }
    setSelectedFarmerAvatar("");
  };

  const handleSubmit = (values) => {
    console.log("these are farmer values in handleSubmit => ", values);
    setIsSubmitting(true);

    let formData = new FormData();
    const token = localStorage.getItem("humblebeeAuthToken");

    formData.append("farmerName", values.farmerName);

    formData.append("location.latitude", location?.lat);
    formData.append("location.longitude", location?.lng);
    formData.append("farmerMobile", values.farmerMobile);
    formData.append("addressLine", values.addressLine);
    formData.append("city", values.city);
    formData.append("district", values.district);
    formData.append("state", values.state);
    formData.append("pincode", values.pincode);
    formData.append("farmerQuote", values.farmerQuote);

    if (selectedFarmerAvatar) {
      formData.append("farmerAvatar", selectedFarmerAvatar);
    }

    const honeyNames = selected.map((item) => item.value);
    // console.log("honeyNames => ", honeyNames);

    // Add each value in the produce array to formData
    honeyNames.forEach((item, index) => {
      formData.append(`produce[${index}]`, item);
      // console.log("item in produce ", item);
    });

    formData.append("bio", customBioValue);

    // Add each file in the media array to formData
    for (let i = 0; i < allMedia.length; i++) {
      formData.append(`media[${i}]`, allMedia[i]);
      // console.log("allMedia[i] => ", allMedia[i]);
      // console.log("typeOf ", typeof allMedia[i]);
    }

    formData.append("isActive", values.isActive);
    formData.append("currentToken", token);

    console.log("this is just above final dispatch");
    dispatch(makeFarmer(formData))
      .then(() => dispatch(getAllFarmers()))
      .then(() => setIsSubmitting(false))
      .catch((err) => toast.error(`${err}`))
      .finally(() => {
        setOpenModal(false);
      });

    // End of handleSubmit
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
  });

  const handleGoogleMapClick = (e) => {
    setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  // useEffect(() => {
  //   if (message?.length > 0) {
  //     toast.info(message);
  //   }
  // }, [message]);

  const [markerKey, setMarkerKey] = useState(0);

  useEffect(() => {
    setMarkerKey((prevKey) => prevKey + 1); // Update the marker key whenever the marker position changes
  }, [location, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinMakeFarmer());
      dispatch(resetMessageinMakeFarmer());
    }

    if (!error && message) {
      toast.success("Farmer was added succesfully");
      dispatch(resetMessageinMakeFarmer());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={farmerSchema}
          validateOnMount
        >
          {(formik) => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Farmer Form</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              {/* Main Bode of Form Starts */}
              <Styles.MainFarmerFormDiv>
                <InputGroup
                  PlaceholderText="Enter Farmer Name"
                  LabelText="Farmer Name"
                  name="farmerName"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />
                <Styles.RowFlex>
                  <SelectGroup
                    PlaceholderText="Select State"
                    LabelText="State"
                    name="state"
                    onChange={(e) => {
                      handleStateChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedState} // Add this line
                    options={states}
                    star="*"
                  />

                  <SelectGroup
                    PlaceholderText="Select District"
                    LabelText="District"
                    name="district"
                    onChange={(e) => {
                      handleDistrictChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedDistrict} // Add this line
                    options={districts}
                    star="*"
                  />
                </Styles.RowFlex>
                <Styles.RowFlex>
                  <SelectGroup
                    PlaceholderText="Select City"
                    LabelText="City"
                    name="city"
                    options={cities}
                    onChange={(e) => {
                      handleCityChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedCity} // Add this line
                    star="*"
                  />

                  <InputGroup
                    PlaceholderText="Enter Farmer Address"
                    LabelText="Farmer Address"
                    name="addressLine"
                    type="text"
                    star="*"
                    onChange={formik.handleChange}
                  />
                </Styles.RowFlex>

                <Styles.RowFlex>
                  <InputGroup
                    PlaceholderText="Enter Farmer Mobile"
                    LabelText="Farmer Mobile"
                    name="farmerMobile"
                    type="text"
                    star="*"
                    onChange={formik.handleChange}
                  />
                  <InputGroup
                    PlaceholderText="Enter Farmer Pincode"
                    LabelText="Farmer Pincode"
                    name="pincode"
                    type="number"
                    star="*"
                    onChange={formik.handleChange}
                  />
                </Styles.RowFlex>

                <div style={{ marginTop: "20px" }}>
                  <label style={{ fontWeight: "bold" }}>
                    Produce
                    <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                  </label>

                  <MultiSelect
                    options={honeyNamesWithLabels}
                    value={selected}
                    onChange={setSelected}
                    labelledBy="Select"
                  />
                  {selected.length <= 0 ? (
                    <span style={{ color: "red" }}>
                      There should be atleast one produce
                    </span>
                  ) : null}
                </div>

                <TextareaGroup
                  PlaceholderText="Enter some Quote from Farmer"
                  LabelText="Farmer Quote"
                  name="farmerQuote"
                  type="text"
                  onChange={formik.handleChange}
                />

                <Styles.MarginTopBottomBox>
                  <Styles.Labels>Bio</Styles.Labels>
                  <ReactQuill
                    //value={formik?.values?.bio}
                    style={{ marginTop: "10px" }}
                    onChange={(value) => handleBioChange(value)}
                    modules={{
                      toolbar: [
                        [{ header: [1, 2, false] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }],
                        ["link", "image"],
                        ["clean"],
                      ],
                    }}
                  />
                </Styles.MarginTopBottomBox>

                <Styles.ColumnFlex>
                  <Styles.Labels>Farmer Avatar</Styles.Labels>
                  <Styles.FileInputClearButtonDiv>
                    <button
                      onClick={(e) => handleClearFarmerAvatarSelection(e)}
                    >
                      <img src={close} alt="clear" />
                    </button>
                    <Styles.FileInput
                      type="file"
                      onChange={(e, index) =>
                        handleFarmerAvatarChange(e, index)
                      }
                      name="farmerAvatar"
                      accept=".pdf, image/*"
                    />
                  </Styles.FileInputClearButtonDiv>

                  <Styles.IMGTextBox>
                    <Styles.IMGBox>
                      {selectedFarmerAvatar ? (
                        <div>
                          {renderPreviewForCurrent(selectedFarmerAvatar, 0)}
                        </div>
                      ) : null}
                    </Styles.IMGBox>
                  </Styles.IMGTextBox>
                </Styles.ColumnFlex>

                <div style={{ marginTop: "10px" }}>
                  <Styles.Labels id="isActiveGroup">
                    Is Farmer Active{" "}
                    <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                  </Styles.Labels>
                  <Styles.RadioButtonGroup
                    role="isActive"
                    aria-labelledby="isActiveGroup"
                  >
                    <label>
                      <Field
                        type="radio"
                        name="isActive"
                        value={true}
                        checked={formik?.values?.isActive == String(true)}
                      />
                      <span style={{ marginLeft: "8px" }}>Yes</span>
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="isActive"
                        value={false}
                        checked={formik?.values?.isActive == String(false)}
                      />
                      <span style={{ marginLeft: "8px" }}>No</span>
                    </label>
                  </Styles.RadioButtonGroup>
                </div>

                <Styles.ColumnFlex>
                  <Styles.Labels>Media</Styles.Labels>
                  <Styles.FileInputClearButtonDiv>
                    <button onClick={(e) => handleClearSelection(e)}>
                      <img src={close} alt="clear" />
                    </button>
                    <Styles.FileInput
                      type="file"
                      multiple
                      onChange={(e, index) => handleMediaChange(e, index)}
                      name="media"
                      accept=".pdf, image/*"
                    />
                  </Styles.FileInputClearButtonDiv>

                  <Styles.IMGTextBox>
                    {/* <p>Current</p> */}
                    <Styles.IMGBox>
                      {allMedia?.length > 0
                        ? allMedia?.map((item, index) => (
                          <div key={index} className="image" style={{ height:"100px",width:"120px",display:"flex" }}>
                          <div>
                            {renderPreviewForCurrent(item, index)}
                          </div>
                          <button className="delete-button" type="button" onClick={() => {
                            
                            Swal.fire({
                              title: 'Delete Confirmation',
                              text: 'Are you sure you want to delete this item?',
                              icon: 'warning',
                              showCancelButton: true,
                              confirmButtonColor: 'rgb(255, 135, 1)',
                              cancelButtonColor: 'lightgrey',
                              confirmButtonText: 'Delete',
                            }).then((result) => {
                              if (result.isConfirmed) {
                                 let filterImage = allMedia.filter((item,i) => i !== index );
                                 setAllMedia(filterImage) 
                                Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                              }
                            });
                            
                            }}>
                              <img src={close} alt="clear"  style={{height:'10px'}}/>
                           </button>
                          </div>
                          ))
                        : null}
                    </Styles.IMGBox>
                  </Styles.IMGTextBox>
                </Styles.ColumnFlex>

                <Styles.ColumnFlex>
                  <Styles.Labels>Location </Styles.Labels>
                  <p>Lat : {location?.lat}</p>
                  <p>Lng : {location?.lng}</p>
                  {isLoaded ? (
                    <GoogleMap
                      zoom={4}
                      center={{ lat: location.lat, lng: location.lng }}
                      mapContainerClassName="map-container"
                      onClick={(e) => handleGoogleMapClick(e)}
                    >
                      {location?.lat && location?.lng && (
                        <Marker
                          key={markerKey}
                          position={{ lat: location.lat, lng: location.lng }}
                        />
                      )}
                    </GoogleMap>
                  ) : (
                    <h6>Google map Not Loaded</h6>
                  )}
                </Styles.ColumnFlex>
              </Styles.MainFarmerFormDiv>

              {/* Main Body of Form stops */}

              <Styles.CreateFarmerButton
                // disabled={!formik.dirty || !formik.isValid}
                type="submit"
              >
                {isSubmitting ? "Submitting..." : "Create"}
              </Styles.CreateFarmerButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default FarmerForm;
