import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
const currentToken = localStorage.getItem("humblebeeAuthToken");
const initialState = {
  status: "",
 beeMitraData: [],
  loading: false,
  error: "",
};

export const getSinglebeeMitraData = createAsyncThunk(
  "getSinglebeeMitraData",
  async (_id) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/bee-mitras/${_id}`,
        {
            headers: {
              authorization: `Bearer ${currentToken}`,
            },
          }
      );
   console.log("singlee",res.data.beemitra)
      return res.data.beemitra;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const resetSingleBeeMitraData = createAction("resetSingleBeeMitraData");

const getSingleBeeMitraSlice = createSlice({
  name: "getSinglebeeMitraData",
  initialState,
  reducers: {},

  extraReducers: {
    [getSinglebeeMitraData.pending]: (state, action) => {
      state.loading = true;

      state.error = "";
    },

    [getSinglebeeMitraData.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.beeMitraData = payload;
      }
    },

    [getSinglebeeMitraData.rejected]: (state, { message }) => {
      state.error = message;
      state.loading = false;
    },
    [resetSingleBeeMitraData]: (state) => {
      state.projectData = [];
    },
  },
});

export default getSingleBeeMitraSlice.reducer;
