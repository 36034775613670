import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../pages/Home";
import ProtectedRoute from "./ProtectedRoute";

import Batches from "../pages/Batches";
import Farmers from "../pages/Farmers";
import Projects from "../pages/Projects";
import SingleFarmer from "../pages/Farmers/components/SingleFarmer/SingleFarmer";
import NoRouteFound from "./NoRouteFound";
import Suppliers from "../pages/Suppliers";
import SingleSupplier from "../pages/Suppliers/components/SingleSupplier/SingleSupplier";
//@ts-ignore
import SingleBatch from "../pages/Batches/components/SingleBatch/SingleBatch";
import UsersTable from "../pages/Users/UsersTable";
import HoneyTable from "../pages/honey/HoneyTable";
import SingleHoney from "../pages/honey/components/SingleHoney/SingleHoney";
import BeeMitras from "../pages/BeeMitras";
import Beekeepers from "../pages/BeeKeepers/Beekeepers";
const RoutesComponent = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />

      <Route element={<ProtectedRoute />}>
      <Route exact path="/projects" element={<Projects />} />
        <Route exact path="/batches" element={<Batches />} />
        <Route exact path="/farmers" element={<Farmers />} />
        <Route exact path="/suppliers" element={<Suppliers />} />
        <Route exact path="/suppliers/:_id" element={<SingleSupplier />} />
        <Route exact path="/batches/:_id" element={<SingleBatch />} />
        <Route exact path="/users" element={<UsersTable />} />
        <Route exact path="/honeys" element={<HoneyTable />} />
        <Route exact path="/beemitras" element={<BeeMitras />} 
        >
           <Route exact path=":projectId" element={<BeeMitras />} />
           </Route>
       
        <Route exact path="/honeys/:_id" element={<SingleHoney />} />
        <Route exact path="/beekeepers" element={<Beekeepers />} />
      </Route>

      <Route exact path="/farmers/:farmerId" element={<SingleFarmer />} />

      <Route path="*" element={<NoRouteFound />} />
    </Routes>
  );
};

export default RoutesComponent;
