/** @format */

import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";

import "react-quill/dist/quill.snow.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../assets/images/close.png";

import {
  getSinglebeeMitraData,
  resetSingleBeeMitraData,
} from "../../../reduxLogic/reducers/beeMitra/getSingleBeeMitraSlice";
import { InputGroup } from "../../../shared/InputGroup/InputGroup";
import { SelectGroup } from "../../../shared/SelectGroup/SelectGroup";
import { SelectGroupForProject } from "../../../shared/SelectGroupForProject/SelectGroupForProject";

import { getAllBeeMitras } from "../../../reduxLogic/reducers/beeMitra/getAllBeeMitrasSlice";

import { getAllProjects } from "../../../reduxLogic/reducers/projects/getAllProjectSlice";
import {
  editBeeMitra,
  resetMessageinEditBeeMitra,
  resetErrorinEditBeeMitra,
} from "../../../reduxLogic/reducers/beeMitra/editBeeMitraSlice";

const initialValues = {
    status: "",
};



const EditStatusModal = (props) => {
  const { projectData } = useSelector((state) => state.allProjects);
  const { error, message } = useSelector((state) => state.editProject);

  const {
    openEditStatusModal,
    setOpenEditStatusModal,
    idToEdit,
    setIdToEdit,
    singleRow,
    setSingleRow,
  } = props;

  const [getbeeMitraData, setbeeMitraData] = useState(
    singleRow || {}
  );

  const [isEditing, setIsEditing] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(
    getbeeMitraData.status
  );

  const formikRef = useRef(null);

  const dispatch = useDispatch();

  const handleGetSingleProject = async () => {
    dispatch(getSinglebeeMitraData(idToEdit));
  };

  useEffect(() => {
    handleGetSingleProject();
  }, [idToEdit]);

  const handleStateChange = (value) => {
    console.log('value: ', value);
    setSelectedStatus(value);

  };

  const handleClose = () => {
    dispatch(resetSingleBeeMitraData());
    setOpenEditStatusModal(false);
  };

  useEffect(() => {
    formikRef.current.setValues(getbeeMitraData);
  }, [getbeeMitraData]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };
  const statusOptions = ["Active", "Inactive"];
  const handleSubmit = (values) => {
    console.log("these are project values in handleSubmit => ", values);
    setIsEditing(true);

    const beeMitraData = {
 
      status: values.status || getbeeMitraData.status,
    };

    let editId = getbeeMitraData?._id;

    dispatch(editBeeMitra({ editId, beeMitraData }))
      .then(() => dispatch(resetSingleBeeMitraData()))
      .then(() => dispatch(getAllBeeMitras()))
      .then(() => setOpenEditStatusModal(false))
      .then(() => setIsEditing(false))
      .catch((err) => toast.error(`${err}`));

    // End of handleSubmit
  };

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinEditBeeMitra());
      dispatch(resetMessageinEditBeeMitra());
    }

    if (!error && message) {
      toast.success("BeeMitra was updated succesfully");
      dispatch(resetMessageinEditBeeMitra());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        {getbeeMitraData ? (
          <Formik
            initialValues={
              getbeeMitraData ? getbeeMitraData : initialValues
            }
            onSubmit={handleSubmit}
            // validationSchema={beeMitraSchema}
            // validateOnMount
            innerRef={formikRef}
          >
            {(formik) => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit BeeMitra status </h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt='Close' />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                {/* Main Bode of Form Starts */}
                <Styles.MainSupplierFormDiv>
                <SelectGroup
                    PlaceholderText='Status'
                    LabelText='Select status'
                    name='status'
                    onChange={(e) => {
                      handleStateChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedStatus}
                    defaultValue={selectedStatus} // Add this line
                    options={statusOptions}
                    star='*'
                  />
                </Styles.MainSupplierFormDiv>

                {/* Main Body of Form stops */}

                <Styles.CreateSupplierButton type='submit'>
                  {!isEditing ? "Edit" : "...editing"}
                </Styles.CreateSupplierButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <h4>There was error fetching data for Supplier with ID {idToEdit}</h4>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default EditStatusModal;
