import React, { useEffect } from "react";
import * as Styles from "./style";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleFarmer } from "../../../../reduxLogic/reducers/farmers/getSingleFarmerSlice";

const SingleFarmer = () => {
  const { farmerId } = useParams();
  const singleFarmer = useSelector((state) => state.getSingleFarmer);
  let farmerData = singleFarmer?.farmersData;
  const {
    farmerName,
    location,
    farmerMobile,
    city,
    state,
    pincode,
    produce,
    bio,
    media,
    qrCode,
    district,
    addressLine,
  } = farmerData;
  console.log("this is singeFarmer from SingleFarmer.jsx ==> ", singleFarmer);
  let dispatch = useDispatch();
  useEffect(() => {
    dispatch(getSingleFarmer(farmerId));
  }, []);
  return (
    <Styles.SFContainer>
      {farmerData ? (
        <Styles.FarmerWrapper>
          <Styles.FarmerTitle>{farmerName}</Styles.FarmerTitle>
          <Styles.FarmerInfo>
            <Styles.FarmerField>
              <Styles.FarmerLabel>Location:</Styles.FarmerLabel>
              <Styles.FarmerValue>
                Latitude :
                {location?.latitude ? (
                  <span> {location?.latitude}</span>
                ) : (
                  <span> Not Available</span>
                )}
                {"  "}, Longitude :
                {location?.longitude ? (
                  <span> {location?.longitude}</span>
                ) : (
                  <span> Not Available</span>
                )}
              </Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>Mobile:</Styles.FarmerLabel>
              <Styles.FarmerValue>{farmerMobile}</Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>Address:</Styles.FarmerLabel>
              <Styles.FarmerValue>
                {addressLine ? addressLine : ""}
              </Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>City:</Styles.FarmerLabel>
              <Styles.FarmerValue>{city}</Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>District:</Styles.FarmerLabel>
              <Styles.FarmerValue>
                {district ? district : ""}
              </Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>State:</Styles.FarmerLabel>
              <Styles.FarmerValue>{state}</Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>Pincode:</Styles.FarmerLabel>
              <Styles.FarmerValue>{pincode}</Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>Produce:</Styles.FarmerLabel>
              <Styles.FarmerValue>
                {produce?.map((honeyObj) => (
                  <span style={{ marginRight: "5px" }}>
                    {honeyObj?.floralSource}
                  </span>
                ))}
              </Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>Bio:</Styles.FarmerLabel>
              <Styles.FarmerValue
                dangerouslySetInnerHTML={{ __html: bio }}
              ></Styles.FarmerValue>
            </Styles.FarmerField>
            <Styles.FarmerField>
              <Styles.FarmerLabel>QR Code :</Styles.FarmerLabel>
              <Styles.FarmerValue>
                <img src={qrCode} alt="QR Code" />
              </Styles.FarmerValue>
            </Styles.FarmerField>
            {media &&
              media.map((file, index) => {
                // Check if the file is a PDF based on its extension
                const isPDF = file.endsWith(".pdf");

                if (isPDF) {
                  // Render a link for PDF files
                  return (
                    <a
                      key={index}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                      {file}
                    </a>
                  );
                } else {
                  // Render an image for other file types
                  return (
                    <Styles.FarmerImage
                      key={index}
                      src={file}
                      alt={`Farmer Image ${index}`}
                      className="image"
                    />
                  );
                }
              })}
          </Styles.FarmerInfo>
        </Styles.FarmerWrapper>
      ) : (
        <h3>There was error fetching farmer</h3>
      )}
    </Styles.SFContainer>
  );
};

export default SingleFarmer;
