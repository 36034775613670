import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePagination, useTable, useSortBy } from "react-table";

import jwt_decode from "jwt-decode";
import { getAllFarmers } from "../../reduxLogic/reducers/farmers/getAllFarmersSlice";
//import { FARMERS_TABLE_COLUMNS } from "../../shared/Constants/Constants";
import * as Styles from "./style";
import FarmerForm from "./components/FarmerForm/FarmerForm";
import { Link } from "react-router-dom";
import { changeDateTimeFormat } from "../../shared/Helper";
import EditFarmerForm from "./components/EditFarmerForm/EditFarmerForm";
import editIcon from "../../assets/images/edit.png";
import deleteIcon from "../../assets/images/delete.png";
import { deleteFarmer } from "../../reduxLogic/reducers/farmers/deleteFarmerSlice";
import { toast } from "react-toastify";
import DeleteModal from "./components/DeleteModal/DeleteModal";
import { getSingleFarmer } from "../../reduxLogic/reducers/farmers/getSingleFarmerSlice";

const FARMERS_TABLE_COLUMNS = [
  { Header: "Farmer", accessor: "farmerName" },
  { Header: "Mobile", accessor: "farmerMobile" },
  { Header: "State", accessor: "state" },
  { Header: "QR Code", accessor: "qrCode" },
  { Header: "Added By", accessor: "addedBy.name" },
  { Header: "Added On", accessor: "addedOn" },
  { Header: "Updated By", accessor: "updatedBy.name" },
  { Header: "Updated On", accessor: "updatedOn" },
  { Header: "Actions" },
];

const Farmers = () => {
  const [openModal, setOpenModal] = useState(false);
  const [openEditFarmerModal, setOpenEditFarmerModal] = useState();
  const [farmerId, setFarmerId] = useState("");
  const [openDeleteModal, setDeleteModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState();
  const [singleRow, setSingleRow] = useState({});

  const { farmersData } = useSelector((state) => state.allFarmers);
  let role;
  const currentToken = localStorage.getItem("humblebeeAuthToken");

  if (currentToken?.length > 0) {
    const decoded = jwt_decode(currentToken);
    role = decoded.role;
  }

  // console.log(
  //   "this is farmersData that is taken from useSelector => ",
  //   farmersData
  // );
  const dispatch = useDispatch();

  const fetchAllFarmersData = () => {
    dispatch(getAllFarmers());
  };

  useEffect(() => {
    fetchAllFarmersData();
  }, [dispatch]);

  const columns = useMemo(() => FARMERS_TABLE_COLUMNS, []);
  const data = useMemo(() => farmersData, [farmersData]);

  const tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },

    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance;
  const { pageIndex } = state;

  const handleModalToggle = () => {
    setOpenModal((prev) => !prev);
  };

  const handleOpenEditModal = (id, singleRow) => {
    setOpenEditFarmerModal(true);
    setFarmerId(id);
    //dispatch(getSingleFarmer(id));
    setSingleRow(singleRow);
  };

  const handleOpenDeleteModal = (id) => {
    setDeleteModal(true);
    setIdToDelete(id);
  };

  return (
    <Styles.FarmerBox>
      <Styles.FarmerTopSection>
        <Styles.ModalButton onClick={handleModalToggle}>
          Create Farmer
        </Styles.ModalButton>
        {openModal ? (
          <FarmerForm openModal={openModal} setOpenModal={setOpenModal} />
        ) : null}
      </Styles.FarmerTopSection>
      <Styles.TableContainer>
        <Styles.Table {...getTableProps()}>
          <Styles.THead>
            {headerGroups.map((headerGroup) => (
              <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((headerColumn) => (
                  <Styles.TH
                    {...headerColumn.getHeaderProps(
                      headerColumn.getSortByToggleProps()
                    )}
                  >
                    {headerColumn.render("Header")}
                    <span>
                      {headerColumn.isSorted
                        ? headerColumn.isSortedDesc
                          ? " 🔼"
                          : " 🔽"
                        : ""}
                    </span>
                  </Styles.TH>
                ))}
              </Styles.THeadRow>
            ))}
          </Styles.THead>

          <Styles.TBody {...getTableBodyProps()}>
            {page.map((singleRow, index) => {
              prepareRow(singleRow);

              return (
                <Styles.TBodyRow {...singleRow.getRowProps()}>
                  {singleRow.cells.map((singleCell, index) => {
                    // console.log("singleCell qrCode ", singleCell);

                    return (
                      <Styles.TD key={index}>
                        {singleCell.column.Header == "QR Code" ? (
                          <span style={{ width: "100px" }}>
                            <img
                              style={{ width: "100%" }}
                              src={`${singleCell.value}`}
                              alt="qrcode"
                            />
                          </span>
                        ) : (
                          <span>
                            {singleCell.column.Header == "Farmer" ? (
                              <Link
                                to={`/farmers/${singleCell?.row?.original?.farmerId}`}
                              >
                                {singleCell.render("Cell")}
                              </Link>
                            ) : (
                              <span>
                                {singleCell.column.id == "addedOn" ||
                                singleCell.column.id == "updatedOn" ? (
                                  <span>
                                    {singleCell?.value?.length > 0
                                      ? changeDateTimeFormat(singleCell?.value)
                                      : "-"}
                                  </span>
                                ) : (
                                  <span>
                                    {singleCell.column.Header === "Actions" ? (
                                      <Styles.ActionSpan>
                                        <button
                                          onClick={() =>
                                            handleOpenEditModal(
                                              singleCell?.row?.original
                                                ?.farmerId,
                                              singleRow?.original
                                            )
                                          }
                                        >
                                          <img src={editIcon} alt="Edit" />{" "}
                                        </button>
                                        {role == "Admin" ? (
                                          <button
                                            onClick={() =>
                                              handleOpenDeleteModal(
                                                singleCell?.row?.original?._id
                                              )
                                            }
                                          >
                                            <img
                                              src={deleteIcon}
                                              alt="Delete"
                                            />
                                          </button>
                                        ) : null}
                                      </Styles.ActionSpan>
                                    ) : (
                                      <span>{singleCell.render("Cell")}</span>
                                    )}
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                        )}
                      </Styles.TD>
                    );
                  })}
                </Styles.TBodyRow>
              );
            })}
          </Styles.TBody>
        </Styles.Table>
      </Styles.TableContainer>

      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page{""} {pageIndex + 1} of {pageOptions.length}{" "}
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span> {"<<"} </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            <span> Previous</span>
          </Styles.PaginationButton>

          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                let jumpToPageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(jumpToPageNumber);
              }}
            />
          </Styles.PaginationInputDiv>

          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            <span>Next </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span>{">>"} </span>
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>
      {openEditFarmerModal ? (
        <EditFarmerForm
          openEditFarmerModal={openEditFarmerModal}
          setOpenEditFarmerModal={setOpenEditFarmerModal}
          farmerId={farmerId}
          singleRow={singleRow}
          setSingleRow={setSingleRow}
        />
      ) : null}

      {openDeleteModal ? (
        <DeleteModal idToDelete={idToDelete} setDeleteModal={setDeleteModal} />
      ) : null}
    </Styles.FarmerBox>
  );
};

export default Farmers;
