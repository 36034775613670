export const Theme = {
  screenSizes: {
    XXS: 360,
    XS: 480,
    S: 640,
    M: 768,
    ML: 820,
    L: 1024,
    XL: 1280,
    XXL: 1440,
  },
  fontSizes: {
    XXS: "14px",
    XS: "16px",
    S: "18px",
    M: "24px",
    ML: "28px",
    L: "32px",
    XL: "36px",
    XXL: "48px",
  },
  colors: {
    white: "#ffffff",
    black: "#000000",
    success: "#78FFBE",
    inprogress: "#FFFA7A",
    failure: "#FF9090",
    orange: "#FFA500",
    red: "#FF0000",
  },
};
