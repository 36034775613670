/** @format */

import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";

import "react-quill/dist/quill.snow.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../assets/images/close.png";

import { getSinglebeeMitraData,resetSingleBeeMitraData } from "../../../reduxLogic/reducers/beeMitra/getSingleBeeMitraSlice";
import { InputGroup } from "../../../shared/InputGroup/InputGroup";

import { SelectGroupForProject } from "../../../shared/SelectGroupForProject/SelectGroupForProject";

import { getAllBeeMitras } from "../../../reduxLogic/reducers/beeMitra/getAllBeeMitrasSlice";

import { getAllProjects } from "../../../reduxLogic/reducers/projects/getAllProjectSlice";
import { editBeeMitra,resetMessageinEditBeeMitra,resetErrorinEditBeeMitra } from "../../../reduxLogic/reducers/beeMitra/editBeeMitraSlice";
import { getAllStates } from "../../../reduxLogic/reducers/projects/getAllStatesSlice";
import { getAllDistrictsOfAState } from "../../../reduxLogic/reducers/projects/getAllDistrictsSlice";


const initialValues = {
  name: "",
  mobile: "",
  alternateMobile: "",
  address: "",
  pincode: "",
  aadhar: "",
  gender: "",
  projectId: "",
  state: null,
  district: null,
};

const beeMitraSchema = Yup.object().shape({
  name: Yup.string().required("BeeMitra Name is required"),
  mobile: Yup.string()
    .required(" Mobile number is required")
    .matches(/^(91\+)?\d{10}$/, " Mobile number is not valid"),
  alternateMobile: Yup.string()
    .nullable()
    .matches(/^(91\+)?\d{10}$/, "Secondary Mobile number is not valid"),

  // gender: Yup.string()
  //   .required("Gender is required")
  //   .oneOf(["Male", "Female"], "Please select a valid gender"),
  address: Yup.string().required("Address is required"),

  aadhar: Yup.string().required("Aadhar number is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  projectId: Yup.string().required("Project is required"),

  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]{6}$/, "Invalid pincode"),
});

const EditBeeMitraForm = (props) => {
 
    const { projectData } = useSelector((state) => state.allProjects);
  const { error, message } = useSelector((state) => state.editProject);

  const {
    openEditModal,
    setOpenEditModal,
    idToEdit,
    setIdToEdit,
    singleRow,
    setSingleRow,
  } = props;



  const [getBeeMitraData, setbeeMitraData] = useState(singleRow || {});
console.log('✌️singleRow --->', singleRow);

  const [isEditing, setIsEditing] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(
    getBeeMitraData?.projectId
  );
    const [selectedState, setSelectedState] = useState(initialValues.state);
    const [selectedDistrict, setSelectedDistrict] = useState(
      initialValues.district
    );


  const formikRef = useRef(null);

  const dispatch = useDispatch();

  const handleGetSingleProject = async () => {
    dispatch(getSinglebeeMitraData(idToEdit));
  };

  useEffect(() => {
    handleGetSingleProject();
  }, [idToEdit]);

    const { allStates } = useSelector((state) => state.allStates);
    const { allDistricts } = useSelector((state) => state.allDistricts || []);

  
  const handleStateChange = (value) => {
    setSelectedStatus(value);
  };

   const fetchAllStates = () => {
     dispatch(getAllStates());
   };

   const fetchAllDistricts = () => {
     dispatch(getAllDistrictsOfAState(selectedState));
   };
  const handleClose = () => {
    dispatch(resetSingleBeeMitraData());
    setOpenEditModal(false);
  };

  useEffect(() => {
    fetchAllStates();
  }, [dispatch]);

    useEffect(() => {
      fetchAllDistricts(selectedState);
    }, [selectedState]);
  
  useEffect(() => {
    formikRef.current.setValues(getBeeMitraData);
  }, [getBeeMitraData]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };

 
  const handleSubmit = (values) => {
    console.log("these are project values in handleSubmit => ", values);
    setIsEditing(true);
  
    const beeMitraData = {
      name: values.name || getBeeMitraData.name,
      mobile: values.mobile || getBeeMitraData.mobile,
      alternateMobile:
        values.alternateMobile || getBeeMitraData.alternateMobile,
      address: values.address || getBeeMitraData.address,
      pincode: values.pincode || getBeeMitraData.pincode,
      aadhar: values.aadhar || getBeeMitraData.aadhar,
      gender: values.gender || getBeeMitraData.gender,
      projectId: values.projectId || getBeeMitraData.projectId,
      state: values.state || getBeeMitraData.state || null,
      district: values.district || getBeeMitraData.district || null,
    };
  
    let editId = getBeeMitraData?._id;
  
    dispatch(editBeeMitra({ editId, beeMitraData }))
      .then(() => dispatch(resetSingleBeeMitraData()))
      .then(() => dispatch(getAllBeeMitras()))
      .then(() => setOpenEditModal(false))
      .then(() => setIsEditing(false))
      .catch((err) => toast.error(`${err}`));
  
    // End of handleSubmit
  };
  

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinEditBeeMitra());
      dispatch(resetMessageinEditBeeMitra());
    }

    if (!error && message) {
      toast.success("BeeMitra was updated succesfully");
      dispatch(resetMessageinEditBeeMitra());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        {getBeeMitraData ? (
          <Formik
            initialValues={getBeeMitraData ? getBeeMitraData : initialValues}
            onSubmit={handleSubmit}
            validationSchema={beeMitraSchema}
            validateOnMount
            innerRef={formikRef}
          >
            {(formik) => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit Beemitra details</h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt="Close" />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                {/* Main Bode of Form Starts */}
                <Styles.MainSupplierFormDiv>
                  <InputGroup
                    PlaceholderText="Enter Project Name"
                    LabelText="Project Name"
                    star="*"
                    name="name"
                    type="text"
                    defaultValue={getBeeMitraData?.name}
                    onChange={formik.handleChange}
                  />
                  <Styles.RowFlex>
                    <InputGroup
                      PlaceholderText="Enter Primary Mobile Number"
                      LabelText="Primary Mobile Number"
                      name="mobile"
                      type="number"
                      star="*"
                      defaultValue={getBeeMitraData?.mobile}
                      onChange={formik.handleChange}
                      onKeyDown={handleKeyDown}
                    />
                    <InputGroup
                      PlaceholderText="Enter Secondary Mobile Number"
                      LabelText="Secondary Mobile Number"
                      name="alternateMobile"
                      type="number"
                      defaultValue={getBeeMitraData?.alternateMobile}
                      onChange={formik.handleChange}
                    />
                  </Styles.RowFlex>
                  <InputGroup
                    PlaceholderText="Address"
                    LabelText="Address"
                    name="address"
                    type="textarea"
                    star="*"
                    defaultValue={getBeeMitraData?.address}
                    onChange={formik.handleChange}
                  />
                  <SelectGroupForProject
                    PlaceholderText="Select state from list"
                    LabelText="Select state from list"
                    name="state"
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                      setSelectedDistrict("");
                      formik.handleChange(e);
                    }}
                    value={selectedState}
                    options={allStates.map((state) => ({
                      label: state.name,
                      value: state.name,
                    }))}
                    star="*"
                  />

                  <SelectGroupForProject
                    PlaceholderText="Select district from list"
                    LabelText="Select district from list"
                    name="district"
                    onChange={(e) => {
                      setSelectedDistrict(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedDistrict}
                    options={allDistricts.map((district) => ({
                      label: district.name,
                      value: district.name,
                    }))}
                    star="*"
                  />

                  <InputGroup
                    PlaceholderText="Pincode"
                    LabelText="Pincode"
                    name="pincode"
                    type="number"
                    defaultValue={getBeeMitraData?.pincode}
                    star="*"
                    onChange={formik.handleChange}
                  />
                  <InputGroup
                    PlaceholderText="Aadhar"
                    LabelText="Aadhar"
                    name="aadhar"
                    type="number"
                    defaultValue={getBeeMitraData?.aadhar}
                    star="*"
                    onChange={formik.handleChange}
                  />

                  <Styles.RowFlex>
                    <div style={{ marginTop: "10px" }}>
                      <Styles.Labels id="isSupplierActiveGroup">
                        Gender
                        <span style={{ color: "red", marginLeft: "2px" }}>
                          *
                        </span>
                      </Styles.Labels>
                      <Styles.RadioButtonGroup
                        role="male"
                        aria-labelledby="isSupplierActiveGroup"
                      >
                        <label>
                          <Field
                            type="radio"
                            name="gender"
                            value="male"
                            checked={formik?.values?.gender === "male"}
                          />
                          <span style={{ marginLeft: "8px" }}>Male</span>
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="gender"
                            value="female"
                            checked={formik?.values?.gender === "female"}
                          />
                          <span style={{ marginLeft: "8px" }}>Female</span>
                        </label>
                      </Styles.RadioButtonGroup>
                    </div>
                  </Styles.RowFlex>
                  <SelectGroupForProject
                    PlaceholderText="Select Project from list"
                    LabelText="Select Project from list"
                    name="projectId"
                    onChange={(e) => {
                      handleStateChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedStatus}
                    defaultValue={selectedStatus}
                    options={projectData.map((project) => ({
                      label: project.name,
                      value: project._id,
                    }))}
                    star="*"
                  />
                </Styles.MainSupplierFormDiv>

                {/* Main Body of Form stops */}

                <Styles.CreateSupplierButton type="submit">
                  {!isEditing ? "Edit" : "...editing"}
                </Styles.CreateSupplierButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <h4>There was error fetching data for Supplier with ID {idToEdit}</h4>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default EditBeeMitraForm;
