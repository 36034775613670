import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "",
  farmersData: [],
  loading: false,
  error: "",
  message: "",
};

export const getSingleFarmer = createAsyncThunk(
  "getSingleFarmer",
  async (farmerId, { rejectWithValue }) => {
    try {
      console.log("farmerId in slice => ", farmerId);
      const res = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/farmers/${farmerId}`
      );
      console.log("res.data from getSingleFarmer ==> ", res.data.singleFarmer);
      return res.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetSingleFarmerData = createAction("resetSingleFarmerData");

const getSingleFarmerSlice = createSlice({
  name: "getSingleFarmer",
  initialState,
  reducers: {},

  extraReducers: {
    [getSingleFarmer.pending]: (state, action) => {
      state.loading = true;

      state.error = "";
    },

    [getSingleFarmer.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        console.log("payload in getSingleFarmerSlice fullfied => ", payload);
        state.farmersData = payload.singleFarmer;
      }
    },

    [getSingleFarmer.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error;
      state.message = message;
      state.loading = false;
    },

    [resetSingleFarmerData]: (state) => {
      state.farmersData = []; // Reset the farmer data
    },
  },
});

export default getSingleFarmerSlice.reducer;
