import styled from "styled-components";

export const SFContainer = styled.div`
  padding: 24px;
`;

export const FarmerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FarmerTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const FarmerInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 16px;
`;

export const FarmerField = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
`;

export const FarmerLabel = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin-right: 16px;
  min-width: 120px;
`;

export const FarmerValue = styled.p`
  font-size: 16px;
`;

export const FarmerImage = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 16px;
`;
