import { Theme } from "./styles/theme";
import { GlobalStyle } from "./styles/globalStyle";
import Home from "./pages/Home";
import RoutesComponent from "./routesComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "./shared/Sidebar";
import "./App.css";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { logOut } from "./reduxLogic/reducers/auth/authSlice";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function App() {
  const location = useLocation();

  const isSingleFarmerPage =
    location.pathname.startsWith("/farmers/") &&
    location.pathname.length > "/farmers/".length;

  function removeExpiredToken() {
    const token = localStorage.getItem("humblebeeAuthToken");
    if (token) {
      const decoded = jwt_decode(token);
      const currentTime = Date.now() / 1000;
      if (decoded.exp < currentTime) {
        // Token has expired, remove it from local storage
        localStorage.removeItem("humblebeeAuthToken");
      }
    }
  }

  useEffect(() => {
    removeExpiredToken();
  }, []);
  return (
    <>
      <GlobalStyle />
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <div className="App">
        {!isSingleFarmerPage ? <Sidebar /> : null}

        <RoutesComponent />
      </div>
    </>
  );
}

export default App;
