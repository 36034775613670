import React from "react";
import { Outlet, Navigate } from "react-router-dom";
import Home from "../pages/Home/index";
import { useSelector } from "react-redux";

const ProtectedRoute = () => {
  const { token } = useSelector((state) => state.auth);
  //console.log("token in protectedRoute ", token);

  const currentToken = token || localStorage.getItem("humblebeeAuthToken");

  return currentToken ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;

// localStorage.getItem("humblebeeAuthToken")
