import React, { useEffect, useState } from "react";
import { LogoutButton, SidebarContainer, StyledLink } from "./style";
import jwt_decode from "jwt-decode";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../reduxLogic/reducers/auth/authSlice";
import { toast } from "react-toastify";

const Sidebar = () => {
  const [toastShown, setToastShown] = useState(false);
  const { token, message } = useSelector((state) => state?.auth);
  //console.log("token from sidebar => ", token);

  const currentToken = localStorage?.getItem("humblebeeAuthToken") || token;
  //console.log("currentToken from sidebar => ", currentToken);

  let role;
  if (currentToken && currentToken !== null && currentToken != undefined) {
    const decoded = jwt_decode(currentToken);
    role = decoded.role;
  }

  let dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logOut());
    setToastShown(true);
  };

  useEffect(() => {
    if (message?.length > 0) {
      toast.info(message);
      setToastShown(false);
    }
  }, [message, toastShown]);

  return (
    <SidebarContainer>
      <StyledLink to="/">Home</StyledLink>
      {currentToken &&
      currentToken != null &&
      currentToken != undefined &&
      role == "Admin" ? (
        <StyledLink to="/users">Users</StyledLink>
      ) : null}
  <StyledLink to="/projects">Projects</StyledLink>
      <StyledLink to="/suppliers">Suppliers</StyledLink>
      <StyledLink to="/batches">Batches</StyledLink>
      <StyledLink to="/farmers">Farmers</StyledLink>
      <StyledLink to="/honeys">Honeys</StyledLink>
      {currentToken && currentToken != null && currentToken != undefined ? (
        <LogoutButton onClick={handleLogout}>Logout</LogoutButton>
      ) : null}
    </SidebarContainer>
  );
};

export default Sidebar;
