import styled from "styled-components";
import { Link } from "react-router-dom";

export const SidebarContainer = styled.div`
  top: 0;
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  //border-right: 2px dotted gray;
  height: 100vh;
  padding: 24px;
  background-color: #fff7e5;
  min-width: 120px;

  @media (max-width: 720px) {
    flex-direction: row;
    height: fit-content;
    align-items: center;
    gap: 2px;
    //background-color: white;
  }
`;

export const StyledLink = styled(Link)`
  font-size: 18px;
  color: black;
  text-decoration: none;
  font-weight: bold;
  :hover {
    font-style: italic;
    text-decoration: underline;
  }

  @media (max-width: 720px) {
    font-size: 14px;
  }
`;

export const LogoutButton = styled.button`
  border: 1px solid red;
  border-radius: 8px;
  padding: 8px;
  min-width: 70px;
  background-color: red;
  color: white;
  cursor: pointer;
  font-size: 14px;
  :hover {
    font-weight: bold;
  }

  @media (max-width: 720px) {
    font-size: 12px;
    //background-color: white;
  }
`;
