import { applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { configureStore } from "@reduxjs/toolkit";
//@ts-ignore
import getAllBatchesSlice from "../reduxLogic/reducers/batches/getAllBatchesSlice";
import makeBatchSlice from "../reduxLogic/reducers/batches/makeBatchSlice";
import getAllFarmersSlice from "./reducers/farmers/getAllFarmersSlice";
import makeFarmerSlice from "./reducers/farmers/makeFarmerSlice";
import authSlice from "./reducers/auth/authSlice";
import getSingleFarmerSlice from "./reducers/farmers/getSingleFarmerSlice";
import getAllSupplierSlice from "./reducers/suppliers/getAllSupplierSlice";
import makeSupplierSlice from "./reducers/suppliers/makeSupplierSlice";
import getSingleSupplierSlice from "./reducers/suppliers/getSingleSupplierSlice";
import getSingleBatchSlice from "./reducers/batches/getSingleBatchSlice";
import editSingleSupplierSlice from "./reducers/suppliers/editSingleSupplierSlice";
import deleteSupplierSlice from "./reducers/suppliers/deleteSupplierSlice";
import editSingleFarmerSlice from "./reducers/farmers/editSingleFarmerSlice";
import deleteFarmerSlice from "./reducers/farmers/deleteFarmerSlice";
import editSingleBatchSlice from "./reducers/batches/editSingleBatchSlice";
import deleteBatchSlice from "./reducers/batches/deleteBatchSlice";
import getAllUsersSlice from "./reducers/auth/getAllUsersSlice";
import getAllHoneySlice from "./reducers/honeys/getAllHoneySlice";
import getSingleHoneySlice from "./reducers/honeys/getSingleHoneySlice";
import makeHoneySlice from "./reducers/honeys/makeHoneySlice";
import editSingleHoneySlice from "./reducers/honeys/editSingleHoneySlice";
import projectCreationSlice from "./reducers/projects/projectCreationSlice"
import getAllProjectSlice from "./reducers/projects/getAllProjectSlice";
import createBeeMitraSlice from "./reducers/beeMitra/createBeeMitraSlice";
import getAllBeeMitrasSlice from "./reducers/beeMitra/getAllBeeMitrasSlice";
import editSingleProjectSlice from "./reducers/projects/editSingleProjectSlice";
import getSingleProjectDetailsSlice from "./reducers/projects/getSingleProjectDetailsSlice";
import deleteProjectSlice from "./reducers/projects/deleteProjectSlice";
import deleteBeeMitraSlice from "./reducers/beeMitra/deleteBeeMitraSlice";
import editBeeMitraSlice from "./reducers/beeMitra/editBeeMitraSlice";
import getSingleBeeMitraSlice from "./reducers/beeMitra/getSingleBeeMitraSlice";
import getAllStatesSlice from "./reducers/projects/getAllStatesSlice";
import getAllDistrictsSlice from "./reducers/projects/getAllDistrictsSlice";
export const store = configureStore({
  reducer: {
    auth: authSlice,
    allUsers: getAllUsersSlice,
    allBatches: getAllBatchesSlice,
    makeBatch: makeBatchSlice,
    getSingleBatch: getSingleBatchSlice,
    updateBatch: editSingleBatchSlice,
    deleteBatch: deleteBatchSlice,
    allFarmers: getAllFarmersSlice,
    getSingleFarmer: getSingleFarmerSlice,
    makeFarmer: makeFarmerSlice,
    editFarmer: editSingleFarmerSlice,
    deleteFarmer: deleteFarmerSlice,
    allSuppliers: getAllSupplierSlice,
    makeSupplier: makeSupplierSlice,
    getSingleSupplier: getSingleSupplierSlice,
    editSingleSupplier: editSingleSupplierSlice,
    deleteSupplier: deleteSupplierSlice,
    allHoneys: getAllHoneySlice,
    singleHoney: getSingleHoneySlice,
    makeHoney: makeHoneySlice,
    editHoney: editSingleHoneySlice,
    createProject:projectCreationSlice,
    allProjects: getAllProjectSlice,
    allDistricts: getAllDistrictsSlice,
    allStates: getAllStatesSlice,
    createBeeMitra:createBeeMitraSlice,
    allBeeMitras:getAllBeeMitrasSlice,
    editProject:editSingleProjectSlice,
    singleProject:getSingleProjectDetailsSlice,
    deleteProject:deleteProjectSlice,
    deleteBeeMitra:deleteBeeMitraSlice,
    editBeeMitra:editBeeMitraSlice,
    singleBeeMitra:getSingleBeeMitraSlice,
    
  },
});
