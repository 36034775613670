import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  status: "",
  allUsers: [],
  loading: false,
  error: "",
};

export const getAllUsers = createAsyncThunk("getAllUsers", async () => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/users`);
    console.log("res.data from Users ==> ", res.data);
    return res.data.users;
  } catch (error) {
    return error.response.data;
  }
});

const getAllUsersSlice = createSlice({
  name: "getAllUsers",
  initialState,
  reducers: {},

  extraReducers: {
    [getAllUsers.pending]: (state, action) => {
      state.loading = true;

      state.error = "";
    },

    [getAllUsers.fulfilled]: (
      state,

      { payload, error }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
      } else {
        state.allUsers = payload;
      }
    },

    [getAllUsers.rejected]: (state, { message }) => {
      state.error = message;
      state.loading = false;
    },
  },
});

export default getAllUsersSlice.reducer;
