import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  singleBeeMitra: {},
  loading: true,
  error: "",
  message: "",
};
const currentToken = localStorage.getItem("humblebeeAuthToken");
export const createBeeMitra = createAsyncThunk(
  "createBeeMitra",
  async (body, { rejectWithValue }) => {
    try {
        const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/bee-mitras/`,
            body,
            {
              headers: {
                authorization: `Bearer ${currentToken}`,
               
              },
            }
          );
          
      console.log("body from createBeeMitra ", body);
      console.log("res.data from createBeeMitra  ", res.data);
      return res.data;
    } catch (error) {
      //@ts-ignore
      console.log("error in createBeeMitra => ", error);
      return rejectWithValue(error.response.data);
    }
  }
);

export const resetMessageinCreateBeeMitra = createAction(
  "resetMessageinCreateBeeMitra"
);
export const resetErrorinCreateBeeMitra = createAction(
  "resetErrorinCreateBeeMitra"
);

const createBeeMitraSlice = createSlice({
  name: "createBeeMitra",
  initialState,
  reducers: {},
  extraReducers: {
    //@ts-ignore
    [createBeeMitra.pending]: (state, action) => {
      state.loading = true;
    },
    //@ts-ignore
    [createBeeMitra.fulfilled]: (
      //@ts-ignore
      state,
      //@ts-ignore
      { payload: { error, singleBeeMitra, message } }
    ) => {
      state.loading = false;
      if (error) {
        state.error = error;
        console.log("this is error in fullfiled to create beemitra", error);
      } else {
        state.singleBeeMitra = singleBeeMitra;
        state.message = message;
      }
    },
    //@ts-ignore
    [createBeeMitra.rejected]: (
      state,
      //@ts-ignore
      { payload: { message, error } }
    ) => {
      state.error = error;
      state.message = message;

      console.log("this is error in rejected createBeeMitra => ", error);
    },
    [resetErrorinCreateBeeMitra]: (state) => {
      state.error = "";
    },
    [resetMessageinCreateBeeMitra]: (state) => {
      state.message = "";
    },
  },
});

export default createBeeMitraSlice.reducer;
