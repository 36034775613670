import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePagination, useTable, useSortBy } from "react-table";
import { Formik, Field, FieldArray } from "formik";
import { toast } from "react-toastify";
import jwt_decode from "jwt-decode";
import { getAllUsers } from "../../reduxLogic/reducers/auth/getAllUsersSlice";
import editIcon from "../../assets/images/edit.png";
import deleteIcon from "../../assets/images/delete.png";
import { Link } from "react-router-dom";
import { changeDateTimeFormat } from "../../shared/Helper";
import * as Yup from "yup";
import * as Styles from "./style";
import axios from "axios";

const USERS_TABLE_COLUMNS = [
  { Header: "Name", accessor: "name" },
  { Header: "Email", accessor: "email" },
  { Header: "Role", accessor: "role" },
  { Header: "Added On", accessor: "addedOn" },
  { Header: "Updated On", accessor: "updatedOn" },
  //   { Header: "Actions" },
];

const UsersTable = () => {
  let dispatch = useDispatch();
  const { token } = useSelector((state) => state.auth);
  const currentToken = localStorage.getItem("humblebeeAuthToken") || token;

  const fetchAllUsersData = () => {
    dispatch(getAllUsers());
  };
  useEffect(() => {
    fetchAllUsersData();
  }, [dispatch, token]);

  let role;
  if (currentToken?.length > 0) {
    const decoded = jwt_decode(currentToken);
    role = decoded?.role;
  }
  const { allUsers } = useSelector((state) => state.allUsers);
  //console.log("allUsers from UsersTable => ", allUsers);

  const columns = useMemo(() => USERS_TABLE_COLUMNS, []);
  const data = useMemo(() => allUsers, [allUsers]);

  //   console.log("columns from userTable => ", columns);
  //   console.log("data from usertable => ", data);

  let tableInstance;
  if (columns?.length > 0 && data?.length > 0) {
  }

  tableInstance = useTable(
    {
      columns: columns,
      data: data,
    },

    useSortBy,
    usePagination
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    pageCount,
    gotoPage,
    state,
  } = tableInstance;
  const { pageIndex } = state;

  const handlePatchRequestChangeRole = async (_id, newRole) => {
    console.log("_id in axios patch => ", _id);

    console.log("newRole in axios patch => ", newRole);
    try {
      await axios.patch(
        `${process.env.REACT_APP_BASE_URL}/users/updaterole/${_id}`,

        { newRole }
      );
      // Show success message or perform any necessary actions
      dispatch(getAllUsers());
      console.log("Success while changing the role => ");
    } catch (error) {
      console.log("There was some error while changing role => ", error);
    }
  };

  const handleRoleChange = (_id, event) => {
    const newRole = event.target.value;
    console.log("_id  => ", _id);
    console.log("event.target  => ", event.target);
    console.log("newRole  => ", newRole);
    handlePatchRequestChangeRole(_id, newRole);
  };
  return (
    <Styles.UserBox>
      {/* <Styles.UserTopSection>
        <Styles.ModalButton>Create User</Styles.ModalButton>
      </Styles.UserTopSection> */}
      <div style={{ height: "10vh" }}></div>
      <Styles.TableContainer>
        {allUsers ? (
          <Styles.Table {...getTableProps()}>
            <Styles.THead>
              {headerGroups.map((headerGroup) => (
                <Styles.THeadRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((headerColumn) => (
                    <Styles.TH
                      {...headerColumn.getHeaderProps(
                        headerColumn.getSortByToggleProps()
                      )}
                    >
                      {headerColumn.render("Header")}
                      <span>
                        {headerColumn.isSorted
                          ? headerColumn.isSortedDesc
                            ? " 🔼"
                            : " 🔽"
                          : ""}
                      </span>
                    </Styles.TH>
                  ))}
                </Styles.THeadRow>
              ))}
            </Styles.THead>

            <Styles.TBody {...getTableBodyProps()}>
              {page.map((singleRow, index) => {
                prepareRow(singleRow);

                return (
                  <Styles.TBodyRow {...singleRow.getRowProps()}>
                    {singleRow.cells.map((singleCell, index) => {
                      // console.log("singleCell qrCode ", singleCell);

                      return (
                        <Styles.TD key={index}>
                          <span>
                            {singleCell.column.id == "addedOn" ||
                            singleCell.column.id == "updatedOn" ? (
                              <span>
                                {singleCell?.value?.length > 0
                                  ? changeDateTimeFormat(singleCell?.value)
                                  : "-"}
                              </span>
                            ) : (
                              <span>
                                {singleCell.column.Header === "Actions" ? (
                                  <Styles.ActionSpan>
                                    {role == "Admin" ? (
                                      <button>
                                        <img src={deleteIcon} alt="Delete" />
                                      </button>
                                    ) : null}
                                  </Styles.ActionSpan>
                                ) : (
                                  <span>
                                    {singleCell.column.id == "role" ? (
                                      <Styles.StyledSelect
                                        value={singleCell.value}
                                        onChange={(event) =>
                                          handleRoleChange(
                                            singleCell?.row?.original?._id,
                                            event
                                          )
                                        }
                                      >
                                        <option value="Admin">Admin</option>
                                        <option value="Editor">Editor</option>
                                      </Styles.StyledSelect>
                                    ) : (
                                      <span>{singleCell.render("Cell")}</span>
                                    )}
                                  </span>
                                )}
                              </span>
                            )}
                          </span>
                        </Styles.TD>
                      );
                    })}
                  </Styles.TBodyRow>
                );
              })}
            </Styles.TBody>
          </Styles.Table>
        ) : (
          <h3>There was some error displaying table</h3>
        )}
      </Styles.TableContainer>

      <Styles.PaginationmainContainer>
        <span>
          <strong>
            Page{""} {pageIndex + 1} of {pageOptions.length}{" "}
          </strong>
        </span>
        <Styles.PaginationDiv>
          <Styles.PaginationButton
            onClick={() => gotoPage(0)}
            disabled={!canPreviousPage}
          >
            <span> {"<<"} </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            <span> Previous</span>
          </Styles.PaginationButton>

          <Styles.PaginationInputDiv>
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                let jumpToPageNumber = e.target.value
                  ? Number(e.target.value) - 1
                  : 0;
                gotoPage(jumpToPageNumber);
              }}
            />
          </Styles.PaginationInputDiv>

          <Styles.PaginationButton onClick={nextPage} disabled={!canNextPage}>
            <span>Next </span>
          </Styles.PaginationButton>
          <Styles.PaginationButton
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            <span>{">>"} </span>
          </Styles.PaginationButton>
        </Styles.PaginationDiv>
      </Styles.PaginationmainContainer>
    </Styles.UserBox>
  );
};

export default UsersTable;
