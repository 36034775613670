/** @format */

import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";

import "react-quill/dist/quill.snow.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../../assets/images/close.png";
import { InputGroup } from "../../../../shared/InputGroup/InputGroup";



import { SelectGroup } from "../../../../shared/SelectGroup/SelectGroup";

import { createProject,resetErrorinProjectCreation,resetMessageinProjectCreation } from "../../../../reduxLogic/reducers/projects/projectCreationSlice";
import { getAllProjects } from "../../../../reduxLogic/reducers/projects/getAllProjectSlice";

const initialValues = {

name: "",
  region: "",
  status: "",
};

const projectSchema = Yup.object().shape({
name: Yup.string().required("Project Name is required"),

  region: Yup.string().required("Region is required"),
  status: Yup.string().required("Status is required"),
});

const ProjectForm = (props) => {
  const { openModal, setOpenModal } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(initialValues.status);

 
  const { error, message } = useSelector((state) => state.createProject);
  console.log("this is error from  project creation form ==> ", error);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenModal((prev) => !prev);
  };

  const statusOptions = ["Active", "Inactive"];
  const handleStateChange = (value) => {
    setSelectedStatus(value);
  };

  const handleSubmit = async(values) => {
    console.log("these are project details in handleSubmit => ", values);
    setIsSubmitting(true);

    const projectData = {
      name: values.name,
      region: values.region,
      status: values.status,
    };

   

    dispatch(createProject(projectData))
    .then(() => dispatch(getAllProjects()))
    .then(() => setOpenModal(false));
  };

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinProjectCreation());
    }

    if (!error && message) {
      toast.success("Project created succesfully");
      dispatch(resetMessageinProjectCreation());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={projectSchema}
          validateOnMount
        >
          {(formik) => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Project Form</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt='Close' />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              {/* Main Bode of Form Starts */}
              <Styles.MainSupplierFormDiv>
                <InputGroup
                  PlaceholderText='Enter Project Name'
                  LabelText='Project Name'
                  name='name'
                  type='text'
                  star='*'
                  onChange={formik.handleChange}
                />
                <Styles.RowFlex>
                  <InputGroup
                    PlaceholderText='Enter Region'
                    LabelText='Region'
                    name='region'
                    type='text'
                    star='*'
                    onChange={formik.handleChange}
                  />

                  <SelectGroup
                    PlaceholderText='Status'
                    LabelText='Select status'
                    name='status'
                    onChange={(e) => {
                      handleStateChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedStatus} // Add this line
                    options={statusOptions}
                    star='*'
                  />
                </Styles.RowFlex>
              </Styles.MainSupplierFormDiv>

              {/* Main Body of Form stops */}

              <Styles.CreateSupplierButton
                // disabled={!formik.dirty || !formik.isValid}
                type='submit'
              >
                {isSubmitting ? "Submitting..." : "Create"}
              </Styles.CreateSupplierButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default ProjectForm;
