import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import close from "../../../../assets/images/close.png";
import { InputGroup } from "../../../../shared/InputGroup/InputGroup";
import { TextareaGroup } from "../../../../shared/TextareaGroup/TextareaGroup";
import { makeFarmer } from "../../../../reduxLogic/reducers/farmers/makeFarmerSlice";
import { getAllFarmers } from "../../../../reduxLogic/reducers/farmers/getAllFarmersSlice";
import { MultiSelect } from "react-multi-select-component";

import deletePng from '../../../../assets/images/delete.png';

import {
  GoogleMap,
  useJsApiLoader,
  LoadScript,
  useLoadScript,
  Marker,
} from "@react-google-maps/api";

import {
  DUMMY_HONEY_TYPES,
  DUMMY_HONEY_TYPES_WITH_LABELS,
} from "../../../../shared/Constants/Constants";
import { BlockData } from "../../../../shared/Constants/blocks";
import { SelectGroup } from "../../../../shared/SelectGroup/SelectGroup";
import { v4 as uuidv4 } from "uuid";
import "./style.css";
import { FieldErrorMessage } from "../../../../shared/ErrorMessage/FieldErrorMessage";
import {
  getSingleFarmer,
  resetSingleFarmerData,
} from "../../../../reduxLogic/reducers/farmers/getSingleFarmerSlice";
import {
  editSingleFarmer,
  resetErrorinEditFarmer,
  resetMessageinEditFarmer,
} from "../../../../reduxLogic/reducers/farmers/editSingleFarmerSlice";
import { getAllHoney } from "../../../../reduxLogic/reducers/honeys/getAllHoneySlice";
import {
  renderPreviewForCurrent,
  renderPreviewForPrevious,
} from "../../../../shared/RenderPreview/RenderPreview";
import { border } from "polished";
import Swal from 'sweetalert2'


const initialValues = {
  farmerName: "",
  location: {
    latitude: "",
    longitude: "",
  },
  farmerMobile: "",
  city: "",
  addressLine: "",
  district: "",
  state: "",
  pincode: "",
  produce: [],
  bio: "",
  media: [],
  isActive: true,
  currentToken: "",
};

const farmerSchema = Yup.object().shape({
  farmerName: Yup.string().required("Farmer Name is required"),
  //produce: Yup.array().min(1, "At least one produce is required"),
  farmerMobile: Yup.string()
    .required("Farmer Mobile is required")
    .matches(/^[+]?[0-9]{10,15}$/, "Mobile number is not valid"),
  city: Yup.string().required("City is required"),
  addressLine: Yup.string().required("Address is required"),
  district: Yup.string().required("District is required"),
  state: Yup.string().required("State is required"),
  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]{6}$/, "Invalid pincode"),
});


const EditFarmerForm = (props) => {
  const {
    openEditFarmerModal,
    setOpenEditFarmerModal,
    farmerId,
    singleRow,
    setSingleRow,
  } = props;
  const singleFarmerInfo = singleRow;
  const [allMedia, setAllMedia] = useState([]);
  const [selectedFarmerAvatar, setSelectedFarmerAvatar] = useState("");
  const [customBioValue, setCustomBioValue] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  const [selectedDistrict, setSelectedDistrict] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [images, setImages] = useState(singleFarmerInfo?.media || []);

  // const fetchSingleFarmerDetails = () => {
  //   dispatch(getSingleFarmer(farmerId));
  // };

  // useEffect(() => {
  //   fetchSingleFarmerDetails();
  // }, [farmerId]);

  // const { farmersData } = useSelector((state) => state.getSingleFarmer);
  const { message, error } = useSelector((state) => state.editFarmer);
  

  const formikRef = useRef(null);
  const [location, setLocation] = useState(null);
  const [selected, setSelected] = useState([]);
  const dispatch = useDispatch();

  // console.log("farmerId==> ", farmerId);
  // console.log("farmersData ==> ", farmersData);
  // console.log("singeFarmerInfo => ", singleFarmerInfo);
  const { honeyData } = useSelector((state) => state.allHoneys);

  const honeyNamesWithLabels = honeyData?.map((honeyObj) => ({
    label: `${honeyObj.honeyId}-${honeyObj.floralSource}`,
    value: honeyObj._id,
  }));

  useEffect(() => {
    if (singleFarmerInfo) {
      formikRef.current.setValues(singleFarmerInfo);
      setCustomBioValue(singleFarmerInfo.bio);

      const preselectedOptions = singleFarmerInfo?.produce?.map((produce) => ({
        label: `${produce.honeyId}-${produce.floralSource}`,
        value: produce._id,
      }));

      //console.log("preselectedOptions", preselectedOptions);
      setSelected(preselectedOptions);

      if (
        singleFarmerInfo?.location &&
        singleFarmerInfo?.location?.latitude &&
        singleFarmerInfo?.location?.longitude
      ) {
        const { latitude, longitude } = singleFarmerInfo?.location ?? {};
        setLocation({ lat: Number(latitude), lng: Number(longitude) });
      }

      setSelectedState(singleFarmerInfo?.state);
      setSelectedDistrict(singleFarmerInfo?.district);
      setSelectedCity(singleFarmerInfo?.city);
    }
    // if (singleFarmerInfo?.farmerAvatar) {
    //   setSelectedFarmerAvatar(singleFarmerInfo?.farmerAvatar);
    // }
  }, [singleFarmerInfo, dispatch]);

  useEffect(() => {
    dispatch(getAllHoney());
  }, []);

  const google = window?.google;

  //console.log("selected => ", selected);
  const handleMediaChange = (e, index) => {
    const { files } = e.target;
    setAllMedia((prevMedia) => [...prevMedia, ...files]);
  };

  const handleFarmerAvatarChange = (e, index) => {
    const { files } = e.target;
    console.log("files for farmerAvatar no [0] => ", files);
    console.log("files for farmerAvatar => ", files[0]);
    setSelectedFarmerAvatar(files[0]);
  };

  const handleClearFarmerAvatarSelection = (e) => {
    e.preventDefault();
    const fileAvatarInput = document.querySelector(
      `input[name="farmerAvatar"]`
    );
    if (fileAvatarInput) {
      fileAvatarInput.value = null;
    }
    setSelectedFarmerAvatar("");
  };

  const handleBioChange = (value) => {
    setCustomBioValue(value);
  };

  const handleClose = () => {
    dispatch(resetSingleFarmerData());
    setOpenEditFarmerModal(setOpenEditFarmerModal(false));
  };

  useEffect(() => {
    // Extract unique states from the JSON data
    const initialUniqueStates = [
      ...new Set(BlockData.map((item) => item.state)),
    ];
    setStates(initialUniqueStates);
  }, []);

  useEffect(() => {
    // Extract unique states from the JSON data
    const initialUniqueDistricts = [
      ...new Set(
        BlockData.filter((item) => item.state === singleFarmerInfo?.state).map(
          (item) => item.district
        )
      ),
    ];
    setDistricts(initialUniqueDistricts);
  }, [singleFarmerInfo]);

  useEffect(() => {
    // Extract unique states from the JSON data
    const initialUniqueCities = [
      ...new Set(
        BlockData.filter(
          (item) => item.district === singleFarmerInfo?.district
        ).map((item) => item.city)
      ),
    ];
    //console.log("initialUniqueCities => ", initialUniqueCities);
    setCities(initialUniqueCities);
  }, [singleFarmerInfo]);

  const handleStateChange = (tempSelectedState) => {
    setSelectedState(tempSelectedState);
    // Extract unique districts based on the selected state
    const uniqueDistricts = [
      ...new Set(
        BlockData.filter((item) => item.state === tempSelectedState).map(
          (item) => item.district
        )
      ),
    ];
    setDistricts(uniqueDistricts);
  };

  const handleDistrictChange = (tempSelectedDistrict) => {
    setSelectedDistrict(tempSelectedDistrict);
    // Extract unique Cities based on the selected district
    const uniqueCities = [
      ...new Set(
        BlockData.filter((item) => item.district === tempSelectedDistrict).map(
          (item) => item.city
        )
      ),
    ];
    setCities(uniqueCities);
  };

  const handleCityChange = (tempSelectedCity) => {
    setSelectedCity(tempSelectedCity);
  };

  const handleClearSelection = (e) => {
    e.preventDefault();
    const fileInput = document.querySelector('input[name="media"]');
    if (fileInput) {
      fileInput.value = null;
    }
    setAllMedia([]);
  };

  const handleSubmit = (values) => {
    // console.log("these are farmer values in handleSubmit => ", values);
    setIsEditing(true);

    let formData = new FormData();
    const token = localStorage.getItem("humblebeeAuthToken");

    let tempFarmerName = values.farmerName
      ? values.farmerName
      : singleFarmerInfo.farmerName;
    formData.append("farmerName", tempFarmerName);

    let templocationlat = location?.lat
      ? location?.lat
      : singleFarmerInfo.location?.latitude;
    formData.append("location.latitude", templocationlat);

    let templocationlng = location?.lng
      ? location?.lng
      : singleFarmerInfo.location?.longitude;
    formData.append("location.longitude", templocationlng);

    let tempFarmerMobile = values.farmerMobile
      ? values.farmerMobile
      : singleFarmerInfo.farmerMobile;
    formData.append("farmerMobile", tempFarmerMobile);

    let tempCity = values.city ? values.city : singleFarmerInfo.city;
    formData.append("city", tempCity);

    // console.log("tempCity => ", tempCity);

    let tempAddressLine = values.addressLine
      ? values.addressLine
      : singleFarmerInfo.addressLine;
    formData.append("addressLine", tempAddressLine);

    let tempDistrict = values.district
      ? values.district
      : singleFarmerInfo.district;
    formData.append("district", tempDistrict);

    // console.log("tempDistrict => ", tempDistrict);

    let tempState = values.state ? values.state : singleFarmerInfo.state;
    formData.append("state", tempState);

    // console.log("tempState => ", tempState);

    let tempPincode = values.pincode
      ? values.pincode
      : singleFarmerInfo.pincode;
    formData.append("pincode", tempPincode);

    let tempFarmerQuote = values.farmerQuote
      ? values.farmerQuote
      : singleFarmerInfo.farmerQuote;
    formData.append("farmerQuote", tempFarmerQuote);

    const honeyNames = selected?.map((item) => item.value) || [];

    // console.log("honeyNames => ", honeyNames);

    // Add each value in the produce array to formData
    honeyNames?.forEach((item, index) => {
      formData.append(`produce[${index}]`, item);
      // console.log("item in produce ", item);
    });

    let tempBio = customBioValue ? customBioValue : singleFarmerInfo.bio;
    formData.append("bio", tempBio);

    let tempFarmerAvatar = selectedFarmerAvatar
      ? selectedFarmerAvatar
      : singleFarmerInfo.farmerAvatar;

    // console.log(
    //   "this is tempFarmerAvatar in handleSubmit ==> ",
    //   tempFarmerAvatar
    // );
    formData.append("farmerAvatar", tempFarmerAvatar);

    let tempAllMedia =
      allMedia && allMedia?.length > 0 ? [...allMedia,...images] : images || singleFarmerInfo.media;
    // Add each file in the media array to formData
    for (let i = 0; i < tempAllMedia?.length; i++) {
      formData.append(`media[${i}]`, tempAllMedia[i]);
      // console.log("allMedia[i] => ", allMedia[i]);
      // console.log("typeOf ", typeof allMedia[i]);
    }

    let tempIsActive = values.isActive;

    formData.append("isActive", tempIsActive);
    formData.append("currentToken", token);

    let editId = singleFarmerInfo?._id;
    // console.log("this is just above final dispatch");
    dispatch(editSingleFarmer({ editId, formData }))
      .then(() => dispatch(getAllFarmers()))
      .then(() => setOpenEditFarmerModal(false))
      .then(() => dispatch(resetSingleFarmerData()))
      .then(() => setIsEditing(false))
      .catch((err) => toast.error(`${err}`));

    // End of handleSubmit
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: `${process.env.REACT_APP_GOOGLE_MAP_KEY}`,
  });

  const handleGoogleMapClick = (e) => {
    //console.log("e.latLng.lat()", e.latLng.lat());
    setLocation({ lat: e.latLng.lat(), lng: e.latLng.lng() });
  };

  const [markerKey, setMarkerKey] = useState(0);

  useEffect(() => {
    setMarkerKey((prevKey) => prevKey + 1); // Update the marker key whenever the marker position changes
  }, [location, singleFarmerInfo?.location, dispatch]);

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinEditFarmer());
      dispatch(resetMessageinEditFarmer());
    }

    if (!error && message) {
      toast.success("Farmer was updated succesfully");
      dispatch(resetMessageinEditFarmer());
    }
  }, [error, dispatch, message]);

  const handleDeleteImage = (formik) => {
    formik.setFieldValue('farmerAvatar', ''); 
  }
  
    const handleDelete = (formik,id) => {
      let temp = {...singleFarmerInfo};
      let media = temp.media
    console.log('media ===>>>>>',media,media.length);
console.log("id",id);
      
      let filterMedia = media.filter((file,index) => {
        return index !== id
      });
      const updatedImages = [...media];
      updatedImages.splice(id, 1);
      

      // setImages(filterMedia);
      // setImages((media) => media.filter((image,index) => index !== id));
      // console.log("images",images);
      formik.setFieldValue("media",updatedImages);
    };
  
  // }

  useEffect(() => {
  }, [selectedFarmerAvatar]);
  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
      

        {singleFarmerInfo ? (
          <Formik
            initialValues={singleFarmerInfo}
            onSubmit={handleSubmit}
            validationSchema={farmerSchema}
            validateOnMount
            innerRef={formikRef}
          >
            {(formik) => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit Farmer Form</h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt="Close" />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                {/* Main Bode of Form Starts */}
                <Styles.MainFarmerFormDiv>
                  <InputGroup
                    PlaceholderText="Enter Farmer Name"
                    LabelText="Farmer Name"
                    name="farmerName"
                    type="text"
                    star="*"
                    defaultValue={singleFarmerInfo?.farmerName}
                    onChange={formik.handleChange}
                  />
                  <Styles.RowFlex>
                    <SelectGroup
                      PlaceholderText="Select State"
                      LabelText="State"
                      name="state"
                      onChange={(e) => {
                        handleStateChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={selectedState}
                      defaultValue={selectedState}
                      options={states}
                      star="*"
                    />

                    <SelectGroup
                      PlaceholderText="Select District"
                      LabelText="District"
                      name="district"
                      onChange={(e) => {
                        handleDistrictChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={selectedDistrict}
                      defaultValue={selectedDistrict}
                      options={districts}
                      star="*"
                    />
                  </Styles.RowFlex>

                  <Styles.RowFlex>
                    <SelectGroup
                      PlaceholderText="Select City"
                      LabelText="City"
                      name="city"
                      onChange={(e) => {
                        handleCityChange(e.target.value);
                        formik.handleChange(e);
                      }}
                      value={selectedCity}
                      defaultValue={selectedCity}
                      options={cities}
                      star="*"
                    />

                    <InputGroup
                      PlaceholderText="Enter Address"
                      LabelText="Farmer Address"
                      name="addressLine"
                      type="text"
                      star="*"
                      defaultValue={singleFarmerInfo?.addressLine}
                      onChange={formik.handleChange}
                    />
                  </Styles.RowFlex>

                  <Styles.RowFlex>
                    <InputGroup
                      PlaceholderText="Enter Farmer Mobile"
                      LabelText="Farmer Mobile"
                      name="farmerMobile"
                      type="text"
                      star="*"
                      defaultValue={singleFarmerInfo?.farmerMobile}
                      onChange={formik.handleChange}
                    />
                    <InputGroup
                      PlaceholderText="Enter Farmer Pincode"
                      LabelText="Farmer Pincode"
                      name="pincode"
                      type="number"
                      star="*"
                      defaultValue={singleFarmerInfo?.pincode}
                      onChange={formik.handleChange}
                    />
                  </Styles.RowFlex>

                  <div style={{ marginTop: "20px" }}>
                    <label style={{ fontWeight: "bold" }}>
                      Produce
                      <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                    </label>

                    <MultiSelect
                      options={honeyNamesWithLabels}
                      value={selected}
                      onChange={setSelected}
                      labelledBy="Select"
                    />
                    {selected && selected?.length <= 0 ? (
                      <span style={{ color: "red" }}>
                        There should be atleast one produce
                      </span>
                    ) : null}
                  </div>

                  <TextareaGroup
                    PlaceholderText="Enter some Quote from Farmer"
                    LabelText="Farmer Quote"
                    name="farmerQuote"
                    type="text"
                    value={formik.values.farmerQuote}
                    onChange={formik.handleChange}
                  />

                  <Styles.MarginTopBottomBox>
                    <Styles.Labels>Bio</Styles.Labels>
                    <ReactQuill
                      //value={formik?.values?.bio}
                      style={{ marginTop: "10px" }}
                      onChange={(value) => handleBioChange(value)}
                      value={customBioValue}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          [
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                          ],
                          [{ list: "ordered" }, { list: "bullet" }],
                          ["link", "image"],
                          ["clean"],
                        ],
                      }}
                    />
                  </Styles.MarginTopBottomBox>

                  <Styles.ColumnFlex>
                    <Styles.Labels>Farmer Avatar</Styles.Labels>
                    {!formik?.values?.farmerAvatar && 
                      <Styles.FileInputClearButtonDiv>
                      <Styles.FileInput
                        type="file"
                        onChange={(e, index) =>
                          handleFarmerAvatarChange(e, index)
                        }
                        name="farmerAvatar"
                        accept=".pdf, image/*"
                      />
                       <button
                        onClick={(e) => handleClearFarmerAvatarSelection(e)}
                        style={{maxWidth : '14px' }}
                      >
                        <img src={deletePng} alt="clear" />
                      </button>
                    </Styles.FileInputClearButtonDiv>
                    }
                    <Styles.IMGContainer>
                      <Styles.IMGTextBox style={{border:'none'}}>
                        <Styles.IMGBox >
                          {selectedFarmerAvatar ? <div>
                             {renderPreviewForCurrent(selectedFarmerAvatar, 0)}
                            </div>: formik?.values?.farmerAvatar ?
                            <div>
                              <img src={formik?.values?.farmerAvatar} alt="imgFile" />
                            </div> :null
                          }
                          {formik?.values?.farmerAvatar && <Styles.FileInputClearButtonDiv>
                           <button type="button"  style={{maxWidth:"20px", }}  onClick={ () =>  handleDeleteImage(formik) } >
                        <img src={deletePng} alt="clear" />
                      </button>
                      </Styles.FileInputClearButtonDiv>}
                        </Styles.IMGBox>
                      </Styles.IMGTextBox>
                    </Styles.IMGContainer>
                  </Styles.ColumnFlex>

                  <div style={{ marginTop: "10px" }}>
                    <Styles.Labels id="isActiveGroup">
                      Is Farmer Active{" "}
                      <span style={{ color: "red", marginLeft: "4px" }}>*</span>
                    </Styles.Labels>
                    <Styles.RadioButtonGroup
                      role="isActive"
                      aria-labelledby="isActiveGroup"
                    >
                      <label>
                        <Field
                          type="radio"
                          name="isActive"
                          value={true}
                          checked={formik?.values?.isActive === true}
                          onChange={() =>
                            formik.setFieldValue("isActive", true)
                          }
                        />
                        <span style={{ marginLeft: "8px" }}>Yes</span>
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="isActive"
                          value={false}
                          checked={formik?.values?.isActive == false}
                          onChange={() =>
                            formik.setFieldValue("isActive", false)
                          }
                        />
                        <span style={{ marginLeft: "8px" }}>No</span>
                      </label>
                    </Styles.RadioButtonGroup>
                  </div>

                  <Styles.ColumnFlex>
                    <Styles.Labels>Media</Styles.Labels>
                    <Styles.FileInputClearButtonDiv>
                      <button onClick={(e) => handleClearSelection(e)}>
                        <img src={close} alt="clear" />
                      </button>
                      <Styles.FileInput
                        type="file"
                        multiple
                        onChange={(e, index) => handleMediaChange(e, index)}
                        name="media"
                        accept=".pdf, image/*"
                      />
                    </Styles.FileInputClearButtonDiv>

                    <Styles.IMGContainer>
                      <Styles.IMGTextBox>
                        <p>Previous</p>
                        <Styles.IMGBox>
                          {/* {formik?.values?.media?.map((item, index) => (
                            <div key={index}>
                              {renderPreviewForPrevious(item, index)}
                            </div>
                            // <img key={item} src={item} alt="media" />
                          ))} */}

              {/* {formik?.values?.media?.map((image,index) => (
                <div key={index} className="image" style={{height:"40px",width:"120px",display:"flex"}}>
                    {/* <img src={image} alt='imgFile' onClick={() => 
                    // handleImageClick(image)
                    console.log("")
                    } /> */}
                    {/* <div className="caption" >
            {image.endsWith(".pdf") ?  <a
              className="anchor-tag"
              href={image}
              target="_blank"
              rel="noopener noreferrer"
      >{`PDF Link ${index}`}</a> : 
      <a className="anchor-tag"
        href={image}
        target="_blank"
        rel="noopener noreferrer" >Photo</a> }</div>
                    <button className="delete-button"  type='button' onClick={() => handleDelete(formik,index) } >
                        <img src={close} alt="clear"  style={{height:'10px'}}/></button>  </div>
                        ))} */} 

            <FieldArray name="media">
              {({ push, remove }) => (
                <div style={{display:"flex"}}>
                  {formik?.values?.media?.map((image, index) => (
                    <div key={index} className="image" 
                    style={{ height:"30px",width:"100px",display:"flex" }}>
                      <div className="caption" style={{background:"lightgrey"}} >
                         {image.endsWith(".pdf") ?  <a className="anchor-tag" href={image}  target="_blank"
                                rel="noopener noreferrer" >{`PDF Link ${index}`}</a> : 
                          <a className="anchor-tag" href={image} target="_blank" rel="noopener noreferrer" style={{marginLeft:3}}>Photo</a> }
                      <button className="delete-button" style={{marginTop:4,marginRight:2}} 
                      type="button" onClick={() => {  
                        Swal.fire({
                          title: 'Delete Confirmation',
                          text: 'Are you sure you want to delete this item?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: 'rgb(255, 135, 1)',
                          cancelButtonColor: 'lightgrey',
                          confirmButtonText: 'Delete',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            // const updatedArray = [...values.myArray];
                            // updatedArray.splice(index, 1);
                            // setFieldValue('myArray', updatedArray);
                            remove(index)
                            setImages(()=> images.filter((_, i) => i !== index))
                            Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                          }
                        });
                       
                        }}>
                        <img src={close} alt="clear"  style={{height:'10px'}}/></button>
                        </div>
                    </div>
                  ))}
                </div>
              )}
            </FieldArray>
                        </Styles.IMGBox>
                      </Styles.IMGTextBox>
                      <Styles.IMGTextBox>
                        <p>Current</p>
                        <Styles.IMGBox >
                          {allMedia && allMedia?.length > 0
                            ? allMedia?.map((item, index) => (
                              <div key={index} className="image" style={{ height:"100px",width:"120px",display:"flex" }}>
                                <div>
                                  {renderPreviewForCurrent(item, index)}
                                </div>
                                <button className="delete-button" type="button" onClick={() => {

                        Swal.fire({
                          title: 'Delete Confirmation',
                          text: 'Are you sure you want to delete this item?',
                          icon: 'warning',
                          showCancelButton: true,
                          confirmButtonColor: 'rgb(255, 135, 1)',
                          cancelButtonColor: 'lightgrey',
                          confirmButtonText: 'Delete',
                        }).then((result) => {
                          if (result.isConfirmed) {
                            let filterImage = allMedia.filter((item,i) => i !== index );
                                                          setAllMedia(filterImage) 
                            Swal.fire('Deleted!', 'The item has been deleted.', 'success');
                          }
                        });
                                   
                                
                                }}
                                  
                                  >
                                    <img src={close} alt="clear"  style={{height:'10px'}}/>
                                 </button>
                                </div>

                              ))
                            : null}
                        </Styles.IMGBox>
                      </Styles.IMGTextBox>
                    </Styles.IMGContainer>
                  </Styles.ColumnFlex>

                  <Styles.ColumnFlex>
                    <Styles.Labels>Location </Styles.Labels>
                    <p>Lat : {location?.lat}</p>
                    <p>Lng : {location?.lng}</p>
                    {isLoaded ? (
                      <span>
                        {location && (
                          <GoogleMap
                            zoom={10}
                            center={{ lat: location.lat, lng: location.lng }}
                            mapContainerClassName="map-container"
                            onClick={(e) => handleGoogleMapClick(e)}
                            google={google}
                          >
                            <Marker
                              // icon={iconMarker}
                              // label="Marker"
                              key={markerKey}
                              position={{
                                lat: Number(location.lat),
                                lng: Number(location.lng),
                              }}
                            />
                          </GoogleMap>
                        )}
                      </span>
                    ) : (
                      <h6>Google map Not Loaded</h6>
                    )}
                  </Styles.ColumnFlex>
                </Styles.MainFarmerFormDiv>

                {/* Main Body of Form stops */}

                <Styles.CreateFarmerButton type="submit">
                  {!isEditing ? "Edit" : "...editing"}
                </Styles.CreateFarmerButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <h3>There was some error in fetching details of Farmer</h3>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default EditFarmerForm;
