/** @format */

import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getAllProjects } from "../../../reduxLogic/reducers/projects/getAllProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../assets/images/close.png";

import { InputGroup } from "../../../shared/InputGroup/InputGroup";
import { SelectGroup } from "../../../shared/SelectGroup/SelectGroup";

import { renderPreviewForCurrent } from "../../../shared/RenderPreview/RenderPreview";
import {
  createBeeMitra,
  resetErrorinCreateBeeMitra,
  resetMessageinCreateBeeMitra,
} from "../../../reduxLogic/reducers/beeMitra/createBeeMitraSlice";
import { getAllBeeMitras } from "../../../reduxLogic/reducers/beeMitra/getAllBeeMitrasSlice";
import { SelectGroupForProject } from "../../../shared/SelectGroupForProject/SelectGroupForProject";
import { getAllStates } from "../../../reduxLogic/reducers/projects/getAllStatesSlice";
import { getAllDistrictsOfAState } from "../../../reduxLogic/reducers/projects/getAllDistrictsSlice";
const initialValues = {
  name: "",
  mobile: "",
  alternateMobile: "",
  address: "",
  pincode: "",
  aadhar: "",
  gender: "",
  projectId: "",
  state: null,
  district: null,
  //status: "",

  //photo: "",
};

const beeMitraSchema = Yup.object().shape({
  name: Yup.string().required("BeeMitra Name is required"),
  mobile: Yup.string()
    .required(" Mobile number is required")
    .matches(/^(91\+)?\d{10}$/, " Mobile number is not valid"),
  alternateMobile: Yup.string()
    .nullable()
    .matches(/^(91\+)?\d{10}$/, "Secondary Mobile number is not valid"),

  // gender: Yup.string()
  //   .required("Gender is required")
  //   .oneOf(["Male", "Female"], "Please select a valid gender"),
  address: Yup.string().required("Address is required"),

  aadhar: Yup.string().required("Aadhar line is required"),
  state: Yup.string().required("State is required"),
  district: Yup.string().required("District is required"),
  projectId: Yup.string().required("Project is required"),

  pincode: Yup.string()
    .required("Pincode is required")
    .matches(/^[0-9]{6}$/, "Invalid pincode"),
});

const BeeMitrasForm = (props) => {
  const { openModal, setOpenModal } = props;

  const [allDocuments, setAllDocuments] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(initialValues.projectId);
  const [selectedState, setSelectedState] = useState(initialValues.state);
  const [selectedDistrict, setSelectedDistrict] = useState(initialValues.district);

  const { error, message } = useSelector((state) => state.createBeeMitra);
  console.log("this is error from  Beemitra Form ==> ", error, message);

  const dispatch = useDispatch();

  const handleClose = () => {
    setOpenModal((prev) => !prev);
  };
  const { projectData } = useSelector((state) => state.allProjects);
  const { allStates } = useSelector((state) => state.allStates);
  const { allDistricts } = useSelector((state) => state.allDistricts || []);

  const handleStateChange = (value) => {
    console.log("tempSelectedState: ", value);
    setSelectedStatus(value);
  };
  const fetchAllProjectsData = () => {
    dispatch(getAllProjects());
  };

  const fetchAllStates = () => {
    dispatch(getAllStates());
  }

  const fetchAllDistricts = () => {
      dispatch(getAllDistrictsOfAState(selectedState));
  };
useEffect(() => {

  fetchAllProjectsData();
  fetchAllStates();
}, [dispatch]);
  
  useEffect(() => {
    fetchAllDistricts(selectedState);
  }, [selectedState]);
  
  const handleSubmit =(values) => {
 

    console.log("inside handle submit");
    console.log("these are supplier values in handleSubmit => ", values);
    setIsSubmitting(true);

    const jsonData = {
      name: values.name,
      mobile: values.mobile,
      alternateMobile: values.alternateMobile,
      address: values?.address,
      aadhar: values?.aadhar,
      pincode: values?.pincode,
      gender: values?.gender,
      projectId: values?.projectId,
      state: values?.state,
      district: values?.district,
    };

    // console.log("JSON data to be submitted:", jsonData);

    dispatch(createBeeMitra(jsonData))
      .then(() => dispatch(getAllBeeMitras()))
      .then(() => setOpenModal(false));
  };

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinCreateBeeMitra());
    }

    if (!error && message) {
      toast.success("Bee mitra added succesfully");
      dispatch(resetMessageinCreateBeeMitra());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={beeMitraSchema}
          validateOnMount
        >
          {(formik) => (
            <Styles.FormWithCloseButton>
              <Styles.CloseButtonDiv>
                <h3>Add BeeMitra</h3>
                <Styles.FormCloseButton onClick={handleClose}>
                  <img src={close} alt="Close" />
                </Styles.FormCloseButton>
              </Styles.CloseButtonDiv>

              {/* Main Bode of Form Starts */}
              <Styles.MainSupplierFormDiv>
                <InputGroup
                  PlaceholderText="Enter Name"
                  LabelText="Name"
                  name="name"
                  type="text"
                  star="*"
                  onChange={formik.handleChange}
                />
                <Styles.RowFlex>
                  <InputGroup
                    PlaceholderText="Enter Primary Mobile Number"
                    LabelText="Primary Mobile Number"
                    name="mobile"
                    type="number"
                    star="*"
                    onChange={formik.handleChange}
                  />

                  <InputGroup
                    PlaceholderText="Enter Secondary Mobile Number"
                    LabelText="Secondary Mobile Number"
                    name="alternateMobile"
                    type="number"
                    onChange={formik.handleChange}
                  />
                </Styles.RowFlex>

                <InputGroup
                  PlaceholderText="Address"
                  LabelText="Address"
                  name="address"
                  type="textarea"
                  star="*"
                  onChange={formik.handleChange}
                />

                <SelectGroupForProject
                  PlaceholderText="Select state from list"
                  LabelText="Select state from list"
                  name="state"
                  onChange={(e) => {
                    setSelectedState(e.target.value);
                    setSelectedDistrict("");
                    formik.handleChange(e);
                  }}
                  value={selectedState}
                  options={allStates.map((state) => ({
                    label: state.name,
                    value: state.name,
                  }))}
                  star="*"
                />

                <SelectGroupForProject
                  PlaceholderText="Select district from list"
                  LabelText="Select district from list"
                  name="district"
                  onChange={(e) => {
                    setSelectedDistrict(e.target.value);
                    formik.handleChange(e);
                  }}
                  value={selectedDistrict}
                  options={allDistricts.map((district) => ({
                    label: district.name,
                    value: district.name,
                  }))}
                  star="*"
                />

                <InputGroup
                  PlaceholderText="Pincode"
                  LabelText="Pincode"
                  name="pincode"
                  type="number"
                  star="*"
                  onChange={formik.handleChange}
                />

                <InputGroup
                  PlaceholderText="Aadhar"
                  LabelText="Aadhar"
                  name="aadhar"
                  type="number"
                  star="*"
                  onChange={formik.handleChange}
                />

                <Styles.RowFlex>
                  <div style={{ marginTop: "10px" }}>
                    <Styles.Labels id="isSupplierActiveGroup">
                      Gender
                      <span style={{ color: "red", marginLeft: "2px" }}>*</span>
                    </Styles.Labels>
                    <Styles.RadioButtonGroup
                      role="male"
                      aria-labelledby="isSupplierActiveGroup"
                    >
                      <label>
                        <Field
                          type="radio"
                          name="gender"
                          value="male"
                          checked={formik?.values?.gender === "male"}
                        />
                        <span style={{ marginLeft: "8px" }}>Male</span>
                      </label>
                      <label>
                        <Field
                          type="radio"
                          name="gender"
                          value="female"
                          checked={formik?.values?.gender === "female"}
                        />
                        <span style={{ marginLeft: "8px" }}>Female</span>
                      </label>
                    </Styles.RadioButtonGroup>
                  </div>
                </Styles.RowFlex>

                <Styles.RowFlex>
                  {/* <InputGroup
                    PlaceholderText='Recommended by'
                    LabelText='Recommended by'
                    name='recommendedBy'
                    type='text'
                     star='*'
                   onChange={formik.handleChange}
                  /> */}
                  <SelectGroupForProject
                    PlaceholderText="Select Project from list"
                    LabelText="Select Project from list"
                    name="projectId"
                    onChange={(e) => {
                      handleStateChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedStatus}
                    options={projectData.map((project) => ({
                      label: project.name,
                      value: project._id,
                    }))}
                    star="*"
                  />
                </Styles.RowFlex>

                {/* <Styles.ColumnFlex>
//                   <Styles.Labels>Upload Photo</Styles.Labels>
//                   <Styles.FileInputClearButtonDiv>
//                     <button onClick={(e) => handleClearSelection(e)}>
//                       <img src={close} alt='clear' />
//                     </button>
//                     <Styles.FileInput
//                       type='file'
//                       onChange={(e, index) => handleDocumentsChange(e, index)}
//                       name='photo'
//                       accept='image/*'
//                     />
//                   </Styles.FileInputClearButtonDiv>

//                   <Styles.IMGTextBox>
//                     <p>Current</p>
//                     <Styles.IMGBox>
//                       {allDocuments?.length > 0
//                         ? allDocuments?.map((item, index) => (
//                             <div key={index}>
//                               {renderPreviewForCurrent(item, index)}
//                             </div>
//                           ))
//                         : null}
//                     </Styles.IMGBox>
//                   </Styles.IMGTextBox>
                </Styles.ColumnFlex> */}
              </Styles.MainSupplierFormDiv>

              {/* Main Body of Form stops */}

              <Styles.CreateSupplierButton
                // disabled={!formik.dirty || !formik.isValid}
                type="submit"
              >
                {isSubmitting ? "Submitting..." : "Create"}
              </Styles.CreateSupplierButton>
            </Styles.FormWithCloseButton>
          )}
        </Formik>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default BeeMitrasForm;
