import React, { useEffect, useState } from "react";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import * as Styles from "./style";
import { InputGroup } from "../../shared/InputGroup/InputGroup";
import { applyAuth } from "../../reduxLogic/reducers/auth/authSlice";
import logo from "../../assets/images/logo.png";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const authSchema = Yup.object().shape({
  email: Yup.string()
    .email("Must be a valid email")
    .required("Emaol is required"),
  password: Yup.string().required("Password is required"),
});

const initialValues = {
  email: "",
  password: "",
};

const Home = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let dispatch = useDispatch();
  let navigate = useNavigate();
  const { token, message } = useSelector((state) => state.auth);
  console.log("token from useSelector in home", token);
  console.log("message from useSelector in home", message);
  const currentToken = localStorage.getItem("humblebeeAuthToken") || token;

  console.log("currentToken from home.jsx", currentToken);
  const handleLogin = (values) => {
    console.log("values of login ", values);

    dispatch(applyAuth(values));
  };

  useEffect(() => {
    if (
      currentToken != undefined &&
      currentToken != null &&
      currentToken?.length > 0
    ) {
      setIsLoggedIn(true);
    }

    if (
      currentToken == null ||
      currentToken == undefined ||
      currentToken?.length <= 0
    ) {
      setIsLoggedIn(false);
    }
  }, [currentToken, handleLogin]);

  // useEffect(() => {
  //   if (message?.length > 0) {
  //     toast.info(message);
  //   }
  // }, [message]);

  return (
    <Styles.HomeContainer>
      {!isLoggedIn ? (
        <Styles.LoginBox>
          <Formik
            initialValues={initialValues}
            onSubmit={handleLogin}
            validationSchema={authSchema}
            validateOnMount
          >
            {(formik) => (
              <Styles.LoginFormikForm>
                <InputGroup
                  PlaceholderText="Enter Email"
                  LabelText="Email"
                  name="email"
                  type="email"
                  onChange={formik.handleChange}
                />

                <InputGroup
                  PlaceholderText="Enter Password"
                  LabelText="Password"
                  name="password"
                  type="password"
                  onChange={formik.handleChange}
                />
                <Styles.LoginButton type="submit">Login</Styles.LoginButton>
              </Styles.LoginFormikForm>
            )}
          </Formik>
        </Styles.LoginBox>
      ) : (
        <Styles.AlternateBox>
          <img src={logo} alt="logo" />
          <h3>An initiative supported by LetsEndorse</h3>
        </Styles.AlternateBox>
      )}
    </Styles.HomeContainer>
  );
};

export default Home;
