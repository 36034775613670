import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Styles from "./style";
import { getSingleBatch } from "../../../../reduxLogic/reducers/batches/getSingleBatchSlice";
import { changeDateTimeFormat } from "../../../../shared/Helper";

const SingleBatch = () => {
  const { _id } = useParams();
  console.log("this is _id taken from req.params => ", _id);
  let dispatch = useDispatch();
  const { batchData } = useSelector((state) => state.getSingleBatch);

  console.log("this is batchData from singlebatch.jsx file => ", batchData);

  const fetchSingleBatchData = async () => {
    dispatch(getSingleBatch(_id));
  };

  useEffect(() => {
    fetchSingleBatchData();
  }, []);
  return (
    <Styles.SFContainer>
      {batchData ? (
        <Styles.BatchWrapper>
          <Styles.BatchTitle>Batch ID : {batchData?.batchId}</Styles.BatchTitle>
          <Styles.BatchInfo>
            <Styles.BatchField>
              <Styles.BatchLabel>Supplier</Styles.BatchLabel>
              <Styles.BatchValue>
                {batchData?.supplier?.supplierName}
              </Styles.BatchValue>
            </Styles.BatchField>
            <Styles.BatchField>
              <Styles.BatchLabel>Date of Order</Styles.BatchLabel>
              <Styles.BatchValue>
                {changeDateTimeFormat(batchData?.dateOfOrder)}
              </Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchField>
              <Styles.BatchLabel>Date Of Delivery</Styles.BatchLabel>
              <Styles.BatchValue>
                {batchData?.dateOfDelivery
                  ? changeDateTimeFormat(batchData?.dateOfDelivery)
                  : "-"}
              </Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchField>
              <Styles.BatchLabel>Nector Source</Styles.BatchLabel>
              <Styles.BatchValue>{batchData?.nectorSource}</Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchField>
              <Styles.BatchLabel>Origin</Styles.BatchLabel>
              <Styles.BatchValue>{batchData?.origin}</Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchField>
              <Styles.BatchLabel>QR Code</Styles.BatchLabel>
              <Styles.BatchValue>
                <Styles.Image src={batchData?.batchQRCode} alt="qrcode" />
              </Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchField>
              <Styles.BatchLabel>Added On</Styles.BatchLabel>
              <Styles.BatchValue>
                {changeDateTimeFormat(batchData?.addedOn)}
              </Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchField>
              <Styles.BatchLabel>Added By</Styles.BatchLabel>
              <Styles.BatchValue>{batchData?.addedBy?.name}</Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchField>
              <Styles.BatchLabel>Updated On</Styles.BatchLabel>
              <Styles.BatchValue>
                {changeDateTimeFormat(batchData?.updatedOn)}
              </Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchField>
              <Styles.BatchLabel>Updated By</Styles.BatchLabel>
              <Styles.BatchValue>
                {batchData?.updatedBy?.name}
              </Styles.BatchValue>
            </Styles.BatchField>

            <Styles.BatchItemContainer>
              {batchData?.batchItems?.map((singleBatchItem, index) => (
                <Styles.BatchItemBox key={index}>
                  {console.log(
                    "this is singlebatchItem ==>> ",
                    singleBatchItem
                  )}
                  <div>
                    <h5>Type of Honey : </h5>
                    <p>{singleBatchItem?.honeyType?.floralSource}</p>
                  </div>

                  <div>
                    <h5>Volume of Honey(kg) : </h5>
                    <p>{singleBatchItem?.volume}</p>
                  </div>

                  <div>
                    <h5>Contributing Farmers : </h5>
                    <p>
                      {/* {singleBatchItem?.contributingFarmers?.farmerName?.join(
                        " , "
                      )} */}
                      {singleBatchItem?.contributingFarmers
                        ?.map((farmer) => farmer.farmerName)
                        .join(", ")}

                      {console.log(
                        "singleBatchItem?.contributingFarmers========>> ",
                        singleBatchItem?.contributingFarmers
                      )}
                    </p>
                  </div>

                  <div>
                    <h5>Bee : </h5>
                    <p>{singleBatchItem?.bee}</p>
                  </div>
                </Styles.BatchItemBox>
              ))}
            </Styles.BatchItemContainer>

            {batchData &&
              batchData?.batchDocuments?.map((file, index) => {
                // Check if the file is a PDF based on its extension
                const isPDF = file.endsWith(".pdf");

                if (isPDF) {
                  // Render a link for PDF files
                  return (
                    <a
                      key={index}
                      href={file}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    >
                      {file}
                    </a>
                  );
                } else {
                  // Render an image for other file types
                  return (
                    <Styles.Image
                      key={index}
                      src={file}
                      alt={`Farmer Image ${index}`}
                      className="image"
                    />
                  );
                }
              })}
          </Styles.BatchInfo>
        </Styles.BatchWrapper>
      ) : (
        <h3>There was some error while getting details for Supplier</h3>
      )}
    </Styles.SFContainer>
  );
};

export default SingleBatch;
