/** @format */

import React, { useState, useRef, useMemo, useEffect } from "react";
import * as Styles from "./style";
import { Formik, Field, FieldArray } from "formik";
import * as Yup from "yup";

import "react-quill/dist/quill.snow.css";

import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import close from "../../../../assets/images/close.png";
import { InputGroup } from "../../../../shared/InputGroup/InputGroup";

import { SelectGroup } from "../../../../shared/SelectGroup/SelectGroup";


import {
  getSingleProject,
  resetSingleProjectData,
} from "../../../../reduxLogic/reducers/projects/getSingleProjectDetailsSlice";



import { getAllProjects } from "../../../../reduxLogic/reducers/projects/getAllProjectSlice";
import {
    editSingleProject,
  resetErrorinEditProject,
  resetMessageinEditProject,
} from "../../../../reduxLogic/reducers/projects/editSingleProjectSlice";

const initialValues = {
  name: "",
  region: "",
  status: "",
};

const projectSchema = Yup.object().shape({
  name: Yup.string().required("Project Name is required"),

  region: Yup.string().required("Region is required"),
  status: Yup.string().required("Status is required"),
});

const EditProjectForm = (props) => {
 

  const { error, message } = useSelector((state) => state.editProject);

  const {
    openEditModal,
    setOpenEditModal,
    idToEdit,
    setIdToEdit,
    singleRow,
    setSingleRow,
  } = props;



  const [singleProjectData, setSingleProjectData] = useState(singleRow || {});

  const [isEditing, setIsEditing] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(
    singleProjectData.status
  );

  const formikRef = useRef(null);

  const dispatch = useDispatch();

  const handleGetSingleProject = async () => {
    dispatch(getSingleProject(idToEdit));
  };

  useEffect(() => {
    handleGetSingleProject();
  }, [idToEdit]);

  const handleStateChange = (value) => {
    setSelectedStatus(value);
  };

  const handleClose = () => {
    dispatch(resetSingleProjectData());
    setOpenEditModal(false);
  };

  useEffect(() => {
    formikRef.current.setValues(singleProjectData);
  }, [singleProjectData]);

  const handleKeyDown = (event) => {
    if (event.keyCode === 38 || event.keyCode === 40) {
      event.preventDefault();
    }
  };

  const statusOptions = ["Active", "Inactive"];
  const handleSubmit = (values) => {
    console.log("these are project values in handleSubmit => ", values);
    setIsEditing(true);
  
    const projectData = {
      name: values.name || singleProjectData.name,
      region: values.region || singleProjectData.region,
      status: values.status || singleProjectData.status,
    };
  
    let editId = singleProjectData?._id;
  
    dispatch(editSingleProject({ editId, projectData }))
      .then(() => dispatch(resetSingleProjectData()))
      .then(() => dispatch(getAllProjects()))
      .then(() => setOpenEditModal(false))
      .then(() => setIsEditing(false))
      .catch((err) => toast.error(`${err}`));
  
    // End of handleSubmit
  };
  

  useEffect(() => {
    if (error) {
      toast.error(`${error}`);
      dispatch(resetErrorinEditProject());
      dispatch(resetMessageinEditProject());
    }

    if (!error && message) {
      toast.success("Project was updated succesfully");
      dispatch(resetMessageinEditProject());
    }
  }, [error, dispatch, message]);

  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        {singleProjectData ? (
          <Formik
            initialValues={
                singleProjectData ? singleProjectData : initialValues
            }
            onSubmit={handleSubmit}
            validationSchema={projectSchema}
            validateOnMount
            innerRef={formikRef}
          >
            {(formik) => (
              <Styles.FormWithCloseButton>
                <Styles.CloseButtonDiv>
                  <h3>Edit Supplier Form</h3>
                  <Styles.FormCloseButton onClick={handleClose}>
                    <img src={close} alt='Close' />
                  </Styles.FormCloseButton>
                </Styles.CloseButtonDiv>

                {/* Main Bode of Form Starts */}
                <Styles.MainSupplierFormDiv>
                  <InputGroup
                    PlaceholderText='Enter Project Name'
                    LabelText='Project Name'
                    star='*'
                    name='name'
                    type='text'
                    defaultValue={singleProjectData?.name}
                    onChange={formik.handleChange}
                  />
                  <Styles.RowFlex>
                    <InputGroup
                      PlaceholderText='Enter Region'
                      LabelText='Region'
                      name='region'
                      type='text'
                      star='*'
                      defaultValue={singleProjectData?.region}
                      onChange={formik.handleChange}
                      onKeyDown={handleKeyDown}
                    />
                  </Styles.RowFlex>

                  <SelectGroup
                    PlaceholderText='Status'
                    LabelText='Select status'
                    name='status'
                    onChange={(e) => {
                      handleStateChange(e.target.value);
                      formik.handleChange(e);
                    }}
                    value={selectedStatus}
                    defaultValue={selectedStatus} // Add this line
                    options={statusOptions}
                    star='*'
                  />
                </Styles.MainSupplierFormDiv>

                {/* Main Body of Form stops */}

                <Styles.CreateSupplierButton type='submit'>
                  {!isEditing ? "Edit" : "...editing"}
                </Styles.CreateSupplierButton>
              </Styles.FormWithCloseButton>
            )}
          </Formik>
        ) : (
          <h4>There was error fetching data for Supplier with ID {idToEdit}</h4>
        )}
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default EditProjectForm;
