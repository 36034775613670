import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as Styles from "./style";
import { getSingleHoney } from "../../../../reduxLogic/reducers/honeys/getSingleHoneySlice";
import { changeDateTimeFormat } from "../../../../shared/Helper";

const SingleHoney = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { honeyData } = useSelector((state) => state.singleHoney);

  console.log("this is single honeyData in jsx => ", honeyData);

  const fetchSingleHoneyDetails = async () => {
    dispatch(getSingleHoney(_id));
  };

  useEffect(() => {
    fetchSingleHoneyDetails();
  }, []);
  return (
    <Styles.SFContainer>
      {honeyData ? (
        <Styles.Wrapper>
          <Styles.Title>Honey</Styles.Title>
          <Styles.Info>
            <Styles.Field>
              <Styles.Label>Floral Source:</Styles.Label>
              <Styles.Value>{honeyData?.floralSource}</Styles.Value>
            </Styles.Field>

            <Styles.Field>
              <Styles.Label>Honey ID:</Styles.Label>
              <Styles.Value>{honeyData?.honeyId}</Styles.Value>
            </Styles.Field>

            <Styles.Field>
              <Styles.Label>Type :</Styles.Label>
              <Styles.Value>{honeyData?.honeyType}</Styles.Value>
            </Styles.Field>

            <Styles.Field>
              <Styles.Label>Region</Styles.Label>
              <Styles.Value>{honeyData?.region}</Styles.Value>
            </Styles.Field>

            <Styles.Field>
              <Styles.Label>Added By :</Styles.Label>
              <Styles.Value>{honeyData?.addedBy?.name}</Styles.Value>
            </Styles.Field>

            <Styles.Field>
              <Styles.Label>Added On :</Styles.Label>
              <Styles.Value>
                {changeDateTimeFormat(honeyData?.addedOn)}
              </Styles.Value>
            </Styles.Field>

            <Styles.Field>
              <Styles.Label>Updated By :</Styles.Label>
              <Styles.Value>{honeyData?.updatedBy?.name}</Styles.Value>
            </Styles.Field>

            <Styles.Field>
              <Styles.Label>Updated On :</Styles.Label>
              <Styles.Value>
                {changeDateTimeFormat(honeyData?.updatedOn)}
              </Styles.Value>
            </Styles.Field>
          </Styles.Info>
        </Styles.Wrapper>
      ) : (
        <h3>There was some error while getting details for Honey </h3>
      )}
    </Styles.SFContainer>
  );
};

export default SingleHoney;
