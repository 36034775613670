import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Styles from "./style";
import { toast } from "react-toastify";
import { deleteFarmer } from "../../../../reduxLogic/reducers/farmers/deleteFarmerSlice";
import { getAllFarmers } from "../../../../reduxLogic/reducers/farmers/getAllFarmersSlice";

const DeleteModal = (props) => {
  const { idToDelete, setDeleteModal } = props;
  let dispatch = useDispatch();
  const token = useSelector((state) => state.token);

  //  const currentToken = localStorage.getItem("humblebeeAuthToken") || token;
  const _id = idToDelete;

  const handleDeleteFarmer = () => {
    dispatch(deleteFarmer(_id))
      .then(() => dispatch(getAllFarmers()))
      .then(() => setDeleteModal(false))
      .then(() => toast.warning(`Farmer with id ${_id} deleted`));
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };
  return (
    <Styles.ModalWrapper>
      <Styles.ModalContent>
        <Styles.InnerContainer>
          <h3>Are you sure you want to delete?</h3>
          <Styles.ButtonDiv>
            <Styles.DeleteButton onClick={handleDeleteFarmer}>
              Yes
            </Styles.DeleteButton>
            <Styles.CloseButton onClick={handleCloseModal}>
              No
            </Styles.CloseButton>
          </Styles.ButtonDiv>
        </Styles.InnerContainer>
      </Styles.ModalContent>
    </Styles.ModalWrapper>
  );
};

export default DeleteModal;
