import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSingleSupplier } from "../../../../reduxLogic/reducers/suppliers/getSingleSupplierSlice";
import * as Styles from "./style";

const SingleSupplier = () => {
  const { _id } = useParams();
  const dispatch = useDispatch();
  const { suppliersData } = useSelector((state) => state.getSingleSupplier);
  const {
    supplierName,
    supplierPrimaryMobile,
    supplierSecondaryMobile,
    supplierAddress,
    supplierEmail,
    contactPerson,
    notes,
    GSTIN,
    documents,
    isActive,
  } = suppliersData;
  console.log("this is single supplierData in jsx => ", suppliersData);

  const fetchSingleSupplierDetails = async () => {
    dispatch(getSingleSupplier(_id));
  };

  useEffect(() => {
    fetchSingleSupplierDetails();
  }, []);
  return (
    <Styles.SFContainer>
      {suppliersData ? (
        <Styles.SupplierWrapper>
          <Styles.SupplierTitle>{supplierName}</Styles.SupplierTitle>
          <Styles.SupplierInfo>
            <Styles.SupplierField>
              <Styles.SupplierLabel>Primary Mobile:</Styles.SupplierLabel>
              <Styles.SupplierValue>
                {supplierPrimaryMobile}
              </Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>Contact Person :</Styles.SupplierLabel>
              <Styles.SupplierValue>{contactPerson}</Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>Secondary Mobile:</Styles.SupplierLabel>
              <Styles.SupplierValue>
                {supplierSecondaryMobile}
              </Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>Email :</Styles.SupplierLabel>
              <Styles.SupplierValue>{supplierEmail}</Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>Address :</Styles.SupplierLabel>
              <Styles.SupplierValue>
                {supplierAddress?.supplierAddressLine}
              </Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>City:</Styles.SupplierLabel>
              <Styles.SupplierValue>
                {supplierAddress?.supplierCity}
              </Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>District:</Styles.SupplierLabel>
              <Styles.SupplierValue>
                {supplierAddress?.supplierDistrict}
              </Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>State:</Styles.SupplierLabel>
              <Styles.SupplierValue>
                {supplierAddress?.supplierState}
              </Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>Pincode:</Styles.SupplierLabel>
              <Styles.SupplierValue>
                {supplierAddress?.supplierPincode}
              </Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>GSTIN:</Styles.SupplierLabel>
              <Styles.SupplierValue>{GSTIN}</Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>Notes :</Styles.SupplierLabel>
              <Styles.SupplierValue
                dangerouslySetInnerHTML={{ __html: notes }}
              ></Styles.SupplierValue>
            </Styles.SupplierField>

            <Styles.SupplierField>
              <Styles.SupplierLabel>is Active :</Styles.SupplierLabel>
              <Styles.SupplierValue>
                {isActive ? "Yes" : "No"}
              </Styles.SupplierValue>
            </Styles.SupplierField>

            {documents?.map((file, index) => {
              // Check if the file is a PDF based on its extension
              const isPDF = file.endsWith(".pdf");

              if (isPDF) {
                // Render a link for PDF files
                return (
                  <a
                    key={index}
                    href={file}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ marginBottom: "20px", marginTop: "20px" }}
                  >
                    {file}
                  </a>
                );
              } else {
                // Render an image for other file types
                return (
                  <Styles.SupplierImage
                    key={index}
                    src={file}
                    alt={`Farmer Image ${index}`}
                    className="image"
                  />
                );
              }
            })}
          </Styles.SupplierInfo>
        </Styles.SupplierWrapper>
      ) : (
        <h3>There was some error while getting details for Supplier</h3>
      )}
    </Styles.SFContainer>
  );
};

export default SingleSupplier;
